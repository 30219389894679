import Layout from 'Components/Layout';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams
import { Services } from 'Services';
import SkeletonLoad from "Components/TableLoad/load";
import { BiPlus } from 'react-icons/bi';
import { AiFillDelete } from 'react-icons/ai';
import FaqClasses from '../faq/Faq.module.css'
import { FaRegEdit } from 'react-icons/fa';
import { Box, Breadcrumbs, Button, Grid, Link, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { Success } from 'Util/Toast';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import ViewUserClasses from '../users/viewUsers/ViewUsers.module.css'
import AddUserClasses from '../users/addUser/AddUser.module.css'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Add } from '@mui/icons-material';

const UpdateConfig = () => {
    const navigate = useNavigate();
    const { configId } = useParams(); // Get the configId from the URL

    const [configData, setConfigData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updatedConfigValue, setUpdatedConfigValue] = useState('');

    useEffect(() => {
        const fetchConfigData = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.getConfig(token);
                const configItem = response.config.find(item => item.configId === parseInt(configId)); // Find the config item with the matching configId
                setConfigData(configItem);
                setUpdatedConfigValue(configItem.configValue); // Set initial value for the updated config value
                setLoading(false);
            } catch (error) {
                console.error('Error fetching config data:', error);
                setLoading(false);
            }
        };

        fetchConfigData();

        // Cleanup function if needed
        return () => {
            // Cleanup code if any
        };
    }, [configId]); // Add configId to the dependency array

    // Function to handle config value change
    const handleConfigValueChange = (event) => {
        setUpdatedConfigValue(event.target.value);
    };

    // Function to update the config value
    const updateConfigValue = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const updatedConfigData = { ...configData, configValue: updatedConfigValue };
            await Services.updateConfig(JSON.stringify(updatedConfigData), token);
            navigate('/config')
            Success('Config value updated successfully');
        } catch (error) {
            console.error('Error updating config value:', error);
        }
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/config')}>
            Config
        </Link>,
        <Typography key="2" color="text.primary">
            Edit Config
        </Typography>,
    ];

    return (
        <Layout Active={'Config'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Update Config</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={AddUserClasses['new-main-config-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                    <div className={AddUserClasses['inside-new-main-btn']}>
                        <button className={FaqClasses['add-button']} onClick={updateConfigValue}>
                            Submit
                        </button>
                    </div>
                </div>
                <div style={{marginTop: '40px'}} className={FaqClasses['create-main-head']} data-aos="zoom-in-down">
                    <div className={FaqClasses['main-h3-head']}>
                        <h3 className={FaqClasses['main-header']}>Update Config</h3>
                    </div>
                    {loading ? (
                        <SkeletonLoad TableHead={[]} /> // You can use a skeleton loader while data is being fetched
                    ) : (
                        <div>
                            {/* Render config data */}
                            {configData && (
                                <div className={FaqClasses['main-config-div']}>
                                    <TextField
                                        label="Config Key"
                                        variant="outlined"
                                        value={configData.configKey}
                                        fullWidth
                                        disabled
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Config Value"
                                        variant="outlined"
                                        value={updatedConfigValue}
                                        fullWidth
                                        margin="normal"
                                        onChange={handleConfigValueChange} // Handle config value change
                                        style={{ marginLeft: '10px' }}
                                    /><br />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default UpdateConfig;
