import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import S3FileUpload from "react-s3";
import { config } from "../../../s3config";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { NullCheck } from "../../../Components/validators";
import MainLoader from "../../../Components/loader/loader";
import { SuccessSwal, warningSwal } from "../../../Util/Toast";
import { compareObjects, CheckIsObjectEmpty } from "../../../Util/Util";
window.Buffer = window.Buffer || require("buffer").Buffer;
function Addcategory() {
    const [loading, setLoading] = useState(false);
    const [params] = useSearchParams();
    const [edit, setEdit] = useState(false);
    const [base64, setbase64] = useState("");
    const [categoryDetails, setCategoryDetails] = useState([]);
    const [mainLoad, setMainLoad] = useState(false);
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        if (params.get("id")) {
            setEdit(true);
            Services.getCategoriesdetailsById(
                "GET",
                null,
                token,
                params.get("id")
            )
                .then((response) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                    if (response.Status === 1) {
                        setCategoryDetails(response.Categories);
                        reset({
                            CategoryName: response.Categories.CategoryName,
                            Status: response.Categories.Status,
                        });
                        setbase64(response.Categories.CategoryImage);
                        setTimeout(() => {
                            setMainLoad(false);
                        }, 200);
                    } else if (response.Status === 0) {
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    useLayoutEffect(() => {
        if (params.get("id")) {
            setMainLoad(true);
        }
        // eslint-disable-next-line
    }, []);
    function filehandleChange(files) {
        console.log(files.type);
        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/webp"
        ) {
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split("/")[1]}`
            );

            S3FileUpload.uploadFile(newfile, {
                ...config,
                dirName: "Categories",
            })
                .then((res) => {
                    setbase64(res.location);
                    setValue(
                        "CategoryImage",
                        `${timestamp}.${String(files.type).split("/")[1]}`
                    );
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert(`${files.name} is neither PNG orjpeg`);
            return false;
        }
        return true;
    }

    const onSubmit = (data) => {
        // setLoading(true);
        if (edit) {
            let body = compareObjects(data, categoryDetails);

            if (!CheckIsObjectEmpty(body)) {
                Services.updateCategories(
                    "PUT",
                    JSON.stringify(body),
                    token,
                    params.get("id")
                )
                    .then((response) => {
                        if (response.Status === 1) {
                            SuccessSwal("Category Updated", response.Message);
                            Navigate(-1);
                        } else if (response.Status === 0) {
                            setLoading(false);
                            warningSwal("Warning", response.Message);
                        }
                    })
                    .catch((err) => {
                        // alert(err);
                        setLoading(false);
                        alert("something went wrong please try again");
                        console.log(err);
                    });
            } else {
                warningSwal("Warning", "Nothing to update");
            }
        } else {
            Services.addCategories("POST", JSON.stringify(data), token)
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Category Added", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    const fileuploadDesign = (
        <div className={TutorClasses["add-item"]}>
            <BsCloudUpload className={TutorClasses["upload-icon"]} />
            <h5>Drag Your Files here</h5>
            <p>(Only *.jpeg ,*.png will be accepted)</p>
        </div>
    );
    const backButton = () => {
        Navigate(-1);
    };
    return (
        <Layout Active={"Categories"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Category</title>
            </Helmet>
            {mainLoad && <MainLoader />}
            <div className={TutorClasses["Container"]}>
                <button onClick={backButton} className={TutorClasses["back"]}>
                    Back
                </button>
                <div className={TutorClasses["wrapper"]}>
                    <h3>{!edit ? "Add Category" : "Update Category"}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <h3>Add Category</h3> */}
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                Category Name{" "}
                                <span className="important">*</span>
                            </label>
                            <input
                                {...register("CategoryName", {
                                    required: "this field is required",
                                    validate: NullCheck,
                                })}
                            />
                            {errors.CategoryName && (
                                <span>{errors.CategoryName.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                Status <span className="important">*</span>
                            </label>
                            <select
                                {...register("Status", {
                                    required: true,
                                })}>
                                <option value="1">Active</option>
                                <option value="2">Inactive</option>
                            </select>
                            {errors.Status && (
                                <span>This field is required</span>
                            )}
                        </div>

                        <div className={TutorClasses["file-upload-wrapper"]}>
                            <header>
                                <h3>
                                    Upload Image{" "}
                                    <span className="important">*</span>
                                </h3>
                            </header>
                            <FileUploader
                                classes={TutorClasses["upload-image"]}
                                multiple={false}
                                handleChange={filehandleChange}
                                name="file"
                                children={fileuploadDesign}
                                // types={fileTypes}
                            />
                        </div>

                        {base64 && (
                            <div
                                className={TutorClasses["file-images-wrapper"]}>
                                <div className={TutorClasses["image"]}>
                                    <img
                                        src={base64}
                                        alt="uploaded-data"
                                        onClick={() => {
                                            window.open(base64, "_blank");
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Addcategory;
