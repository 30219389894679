import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, MenuItem } from '@mui/material';
import { Country, State } from 'country-state-city'; // Importing data from country-state-city library
import AddUserClasses from '../addUser/AddUser.module.css';


const AddressForm = ({ open, editMode, formValues, setFormValues, handleClose, handleSave }) => {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [errors, setErrors] = useState({
        mobileNumber: '',
        pincode: '',
    });
    const [formValid, setFormValid] = useState(true); // State to track overall form validity

    useEffect(() => {
        setCountries(Country.getAllCountries());
    }, []);

    useEffect(() => {
        // Check form validity whenever errors or formValues change
        validateForm();
    }, [errors, formValues]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevState => ({ ...prevState, [name]: value }));

        // Validate fields
        if (name === 'mobileNumber') {
            validateMobileNumber(value);
        } else if (name === 'pincode') {
            validatePincode(value);
        }
    };

    const handleCountryChange = (event) => {
        const selectedCountryCode = event.target.value;
        const selectedCountry = countries.find(country => country.isoCode === selectedCountryCode);

        setFormValues(prevState => ({
            ...prevState,
            country: selectedCountry.name,
            countryCode: selectedCountry.isoCode,
            state: '', // Reset state when country changes
        }));

        setStates(State.getStatesOfCountry(selectedCountryCode));
    };

    const handleStateChange = (event) => {
        const selectedState = event.target.value;
        setFormValues(prevState => ({
            ...prevState,
            state: selectedState,
        }));
    };

    const validateMobileNumber = (value) => {
        // Regex pattern for 10 digit numeric mobile number
        const regex = /^[0-9]{10}$/;
        if (!regex.test(value)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                mobileNumber: 'Mobile number must be a 10-digit number.',
            }));
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                mobileNumber: '',
            }));
        }
    };

    const validatePincode = (value) => {
        // Regex pattern for 6 digit numeric pincode (India example)
        const regex = /^[0-9]{6}$/;
        if (!regex.test(value)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                pincode: 'Pincode must be a 6-digit number.',
            }));
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                pincode: '',
            }));
        }
    };

    const validateForm = () => {
        // Check if there are any errors in the form
        const isMobileNumberValid = !errors.mobileNumber;
        const isPincodeValid = !errors.pincode;
        const isFormValid = isMobileNumberValid && isPincodeValid;

        setFormValid(isFormValid);
    };

    const handleSaveClick = () => {
        // Check if form is valid before calling API
        if (formValid) {
            // Call your API save function here
            handleSave();
        } else {
            // Optionally, display a message or handle the invalid form state
            console.log('Form is not valid. Please correct errors before saving.');
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{editMode ? 'Edit Address' : 'Add Address'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            value={formValues.firstName}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Last Name"
                            name="lastName"
                            value={formValues.lastName}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Company"
                            name="company"
                            value={formValues.company}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Mobile Number"
                            name="mobileNumber"
                            value={formValues.mobileNumber}
                            onChange={handleChange}
                            fullWidth
                            error={!!errors.mobileNumber}
                            helperText={errors.mobileNumber}
                            InputProps={{
                                inputProps: {
                                    pattern: "[0-9]{10}", // Example pattern for 10 digit numeric mobile number
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Flat No"
                            name="flatNo"
                            value={formValues.flatNo}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Delivery Address"
                            name="deliveryAddress"
                            value={formValues.deliveryAddress}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Area"
                            name="area"
                            value={formValues.area}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            label="Country"
                            name="countryCode"
                            value={formValues.countryCode}
                            onChange={handleCountryChange}
                            fullWidth
                        >
                            {countries.map((country) => (
                                <MenuItem key={country.isoCode} value={country.isoCode}>
                                    {country.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            select
                            label="State"
                            name="state"
                            value={formValues.state}
                            onChange={handleStateChange}
                            fullWidth
                            disabled={!states.length}
                        >
                            {states.map((state) => (
                                <MenuItem key={state.isoCode} value={state.name}>
                                    {state.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="City"
                            name="city"
                            value={formValues.city}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Pincode"
                            name="pincode"
                            value={formValues.pincode}
                            onChange={handleChange}
                            fullWidth
                            error={!!errors.pincode}
                            helperText={errors.pincode}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={AddUserClasses['useraddress-mod']}>
                <button className={AddUserClasses['useraddress-cancel-mod']} onClick={handleClose} color="secondary">
                    Cancel
                </button>
                <button className={AddUserClasses['useraddress-save-mod']} onClick={handleSaveClick} color="primary" disabled={!formValid}>
                    Save
                </button>
            </DialogActions>
        </Dialog>
    );
};

export default AddressForm;
