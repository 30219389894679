import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Hourglass } from 'react-loader-spinner';
import { Popover, Typography } from '@mui/material';
import S3 from 'react-s3';
import { FaFilePdf } from 'react-icons/fa';
import { RiDeleteBinFill } from "react-icons/ri";
import { Success, Error } from 'Util/Toast';
import { Services } from 'Services';
import EditProdClasses from './EditProduct.module.css';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import TutorClasses from '../../Categories/addCategory/index.module.css';
import CouponClasses from '../../coupons/Coupon.module.css';
import EditOrderGeneralClasses from '../../orders/editOrder/EditOrder.module.css';
import EditUserClasses from '../../users/editUser/EditUser.module.css';

window.Buffer = window.Buffer || require('buffer').Buffer;

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const config = {
    bucketName: process.env.REACT_APP_BUCKETNAME,
    dirName: 'productImages',
    region: 'ap-south-1',
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

const EditProductGallery = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [uploadedImageEndpoints, setUploadedImageEndpoints] = useState([]);
    const [imageEndpoints, setImageEndpoints] = useState([]);
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const [deletePopoverAnchorEl, setDeletePopoverAnchorEl] = useState(null);
    const [productVideos, setProductVideos] = useState([]);
    const [newVideoUrl, setNewVideoUrl] = useState('');

    const navigate = useNavigate();

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        const filePreviews = files.map(file => ({
            file,
            preview: URL.createObjectURL(file),
        }));

        setFileData(prevFileData => prevFileData.concat(filePreviews));

        files.forEach(async (file) => {
            const timestamp = Date.now();
            const newFile = new File([file], `${timestamp}.${String(file.type).split('/')[1]}`);

            try {
                const res = await S3.uploadFile(newFile, config);
                const imageUrl = res.location;
                const endpoint = `${imageUrl.split('/').pop()}`; // Extract the endpoint
                setUploadedImageEndpoints(prevEndpoints => [...prevEndpoints, endpoint]);
                Success('File uploaded successfully');
            } catch (uploadError) {
                Error('Failed to upload file');
            }
        });
    };

    const handleDeleteImage = (endpoint) => {
        setImageEndpoints(prevEndpoints => prevEndpoints.filter(imgEndpoint => imgEndpoint !== endpoint));
    };

    const handleDeleteVideo = (videoUrl) => {
        setProductVideos(prevVideos => prevVideos.filter(video => video !== videoUrl));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
            const updatedProductImages = [...imageEndpoints, ...uploadedImageEndpoints];
            const updatedProduct = { ...product, productImage: updatedProductImages, productVideo: JSON.stringify(productVideos) };
            const response = await Services.editProduct(productId, JSON.stringify(updatedProduct), token);
            if (response.Status === 1) {
                navigate('/products');
                Success(response.Message);
            } else {
                setError('Failed to update product');
            }
        } catch (error) {
            setError('Error updating product');
        }
    };

    const handleVideoUrlChange = (e) => {
        setNewVideoUrl(e.target.value);
    };

    const addVideoUrl = () => {
        if (newVideoUrl) {
            setProductVideos(prevVideos => [...prevVideos, newVideoUrl]);
            setNewVideoUrl('');
        }
    };

    const parseProductVideos = (videoString) => {
        try {
            return JSON.parse(videoString);
        } catch (error) {
            console.error('Error parsing product videos:', error);
            return [];
        }
    };

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getProductDetails(productId, token);
                if (response.Status === 1) {
                    await delay(2000); // 2-second delay for simulation
                    const fetchedProduct = response.product;
                    setProduct(fetchedProduct);
                    const existingImageEndpoints = fetchedProduct.productImage.map(imageUrl => `${imageUrl.split('/').pop()}`);
                    setImageEndpoints(existingImageEndpoints);
                    setProductVideos(parseProductVideos(fetchedProduct.productVideo));
                } else {
                    setError('Failed to fetch product details.');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [productId]);

    const handlePopoverOpen = (event) => {
        setPopoverAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    };

    const handleDeletePopoverOpen = (event) => {
        setDeletePopoverAnchorEl(event.currentTarget);
    };

    const handleDeletePopoverClose = () => {
        setDeletePopoverAnchorEl(null);
    };

    const popoverOpen = Boolean(popoverAnchorEl);
    const deletePopoverOpen = Boolean(deletePopoverAnchorEl);

    if (loading) {
        return (
            <div className={EditOrderGeneralClasses['spinner-container']}>
                <Hourglass
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="hourglass-loading"
                    colors={['#23232A', '#60E19B']}
                />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!product) {
        return <div>No product found.</div>;
    }

    return (
        <div>
            <div>
                <div>
                    <div className={EditProdClasses['main-prod-over-btn']}>
                        <button onClick={handleSubmit} className={EditProdClasses['update-prod-overview-btn']} type="submit">Save Changes</button>
                    </div>
                    <table className={AddUserClasses['user-table']}>
                        <thead>
                            <tr>
                                <th colSpan="2" className={AddUserClasses['table-header']}>Add Product Image</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={AddUserClasses['table-value']}>
                                    <div className={TutorClasses['upload-image']}>
                                        {fileData.length > 0 && fileData.map((file, index) => (
                                            <img
                                                key={index}
                                                src={file.preview}
                                                alt={`Selected ${index}`}
                                                className={CouponClasses['uploaded-coupon-image']}
                                            />
                                        ))}
                                        <div className={TutorClasses['add-item']} onClick={() => document.getElementById("file-input").click()}>
                                            <FaFilePdf className={TutorClasses['upload-icon']} />
                                            <h5>Drag & Drop</h5>
                                            <p>PDF</p>
                                            <p>or</p>
                                            <button className={AddUserClasses['Browse-btn']}>Browse</button>
                                            <p>(Only *.jpeg, *.png will be accepted)</p>
                                        </div>
                                        <input
                                            type="file"
                                            id="file-input"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            multiple
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <table className={ViewUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={ViewUserClasses['table-header']}>Images</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={ViewUserClasses['table-value']}>
                                {imageEndpoints.length > 0 ? (
                                    imageEndpoints.map((endpoint, index) => (
                                        <div key={index} style={{ display: 'inline-block', position: 'relative', margin: '10px' }}>
                                            <img
                                                src={`https://d81sw8exyf6z3.cloudfront.net/${endpoint}`}
                                                alt={`Product ${index + 1}`}
                                                style={{ width: '453px', height: '200px' }}
                                            />
                                            <button
                                                onClick={() => handleDeleteImage(endpoint)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                    background: 'white',
                                                    color: 'black',
                                                    border: 'none',
                                                    borderRadius: '50%',
                                                    width: '20px',
                                                    height: '20px',
                                                    cursor: 'pointer'
                                                }}
                                                onMouseEnter={handleDeletePopoverOpen}
                                                onMouseLeave={handleDeletePopoverClose}
                                            >
                                                <RiDeleteBinFill />
                                            </button>
                                            <Popover
                                                id="delete-popover"
                                                sx={{
                                                    pointerEvents: 'none',
                                                }}
                                                open={deletePopoverOpen}
                                                anchorEl={deletePopoverAnchorEl}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                onClose={handleDeletePopoverClose}
                                                disableRestoreFocus
                                            >
                                                <Typography className={EditUserClasses['popover-radio']} sx={{ p: 1 }}>Delete this image.</Typography>
                                            </Popover>
                                        </div>
                                    ))
                                ) : (
                                    'No images available'
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <table className={ViewUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={ViewUserClasses['table-header']}>Videos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={ViewUserClasses['table-edit-value']}>
                                {productVideos.length > 0 ? (
                                    productVideos.map((videoUrl, index) => (
                                        <div key={index} style={{ display: 'inline-block', position: 'relative', margin: '10px' }}>
                                            <iframe
                                                title={`Product Video ${index + 1}`}
                                                width="453"
                                                height="200"
                                                src={videoUrl.includes('youtube.com') ? `${videoUrl.replace("watch?v=", "embed/")}` : videoUrl}
                                                frameBorder="0"
                                                allowFullScreen
                                            />
                                            <button
                                                onClick={() => handleDeleteVideo(videoUrl)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '0',
                                                    background: 'white',
                                                    color: 'black',
                                                    border: 'none',
                                                    borderRadius: '50%',
                                                    width: '20px',
                                                    height: '20px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <RiDeleteBinFill />
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    'No videos available'
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className={ViewUserClasses['table-edit-value']}>
                                <input
                                    type="text"
                                    value={newVideoUrl}
                                    onChange={handleVideoUrlChange}
                                    placeholder="Enter YouTube video URL"
                                    style={{ width: '100%', padding: '10px', margin: '10px 0' }}
                                />
                                <button
                                    onClick={addVideoUrl}
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        backgroundColor: '#60E19B',
                                        border: 'none',
                                        cursor: 'pointer',
                                        color: 'white'
                                    }}
                                >
                                    Add Video
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default EditProductGallery;
