import Layout from 'Components/Layout';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import { useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import SkeletonLoad from "Components/TableLoad/load";
import { BiPlus, BiMinus } from 'react-icons/bi';
import { Typography, TextField, Button } from '@mui/material';
import Collapse from 'react-collapse';
import HelpClasses from '../help/Help.module.css';
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaRegEdit } from 'react-icons/fa';
import { Success } from 'Util/Toast';

const Help = () => {
    const navigate = useNavigate();
    const [helps, setHelps] = useState([]);
    const [expandedItems, setExpandedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newlyAddedFields, setNewlyAddedFields] = useState([]); // State to track newly added fields
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // State to track if submit button should be disabled

    useEffect(() => {
        const fetchHelps = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.getHelp(token);
                console.log('Help data:', response);
                setHelps(response.helps);
                setExpandedItems(new Array(response.helps.length).fill(false));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching help data:', error);
                setLoading(false);
            }
        };

        fetchHelps();
    }, []);

    const toggleExpand = (index) => {
        setExpandedItems(prevState => {
            const updatedItems = [...prevState];
            updatedItems[index] = !updatedItems[index];
            return updatedItems;
        });
    };

    const handleAdd = (index) => {
        setHelps(prevHelps => {
            const newHelps = [...prevHelps];
            newHelps[index].solutions.push({ Question: '', Answer: '', isNew: true });
            return newHelps;
        });
        setNewlyAddedFields(prevFields => [...prevFields, { helpIndex: index, solutionIndex: helps[index].solutions.length - 1 }]);
        setIsSubmitDisabled(true); // Disable the submit button
    };

    const handleCancel = (helpIndex, solutionIndex) => {
        setHelps(prevHelps => {
            const newHelps = [...prevHelps];
            newHelps[helpIndex].solutions.splice(solutionIndex, 1); // Remove the newly added solution
            return newHelps;
        });
        setNewlyAddedFields(prevFields => prevFields.filter(field => !(field.helpIndex === helpIndex && field.solutionIndex === solutionIndex)));
        // Check if there are any newly added fields with empty values
        const isEmpty = newlyAddedFields.some(field => (
            !helps[field.helpIndex]?.solutions[field.solutionIndex]?.Question ||
            !helps[field.helpIndex]?.solutions[field.solutionIndex]?.Answer
        ));
        setIsSubmitDisabled(isEmpty); // Disable the submit button if there are empty newly added fields
    };


    const handleUpdate = async (helpId, updatedHelpData) => {
        let body = {
            heading:updatedHelpData.heading,
            solutions:updatedHelpData.solutions,
        }
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.updateHelp(helpId, JSON.stringify(body), token);
            console.log('Help updated successfully');
    
            // Reset isNew flag for all solutions
            const updatedHelps = helps.map(help => ({
                ...help,
                solutions: Array.isArray(help.solutions) ? help.solutions.map(solution => ({ ...solution, isNew: false })) : [] // Ensure solutions is an array
            }));
    
            setHelps(updatedHelps);
            setNewlyAddedFields([]); // Reset newly added fields
            setIsSubmitDisabled(true); // Disable the submit button
            Success(response.Message);
        } catch (error) {
            console.error('Error updating help:', error);
        }
    };
    

    const handleInputChange = (index, solutionIndex, field, value) => {
        const newHelps = [...helps];
        newHelps[index].solutions[solutionIndex][field] = value;
        setHelps(newHelps);
        setIsSubmitDisabled(
            !value.trim() || // Disable if value is empty or contains only whitespace
            newlyAddedFields.some(field => (
                !helps[field.helpIndex].solutions[field.solutionIndex].Question ||
                !helps[field.helpIndex].solutions[field.solutionIndex].Answer
            )) // Disable if there are any other empty newly added fields
        );
    };

    const handleEdit = (helpId) => {
        navigate(`/updatehelp/${helpId}`);
    };

    return (
        <Layout Active={'Help'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Help</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={CommonClasses['control-btns']}>
                    <Button style={{marginBottom: '20px'}}
                        className={CommonClasses['button-for-add']}
                        onClick={() => {
                            navigate("/createhelp");
                        }}>
                        <BiPlus size={20} /> Create Help
                    </Button>
                </div>
                <h3 className={ProductClasses['main-header']}>Help<span className={ProductClasses['count-of-users']}>({helps.length})</span></h3>
                    {loading ? (
                        <SkeletonLoad TableHead={["HELP", "ACTIONS"]} />
                    ) : (
                        <div>
                            {/* Render help data */}
                            {helps.map((helpItem, index) => (
                                <div key={index}>
                                    <div className={HelpClasses['main-expand-edit']}>
                                        <div className={HelpClasses["heading-wrapper"]} onClick={() => toggleExpand(index)}>
                                            <Typography variant="h6" className={HelpClasses["help-heading"]}>{helpItem.heading}</Typography>
                                            {expandedItems[index] ? <BiMinus className={HelpClasses["arrow-icon"]} /> : <MdKeyboardArrowDown className={HelpClasses["arrow-icon"]} />}
                                        </div>
                                        <button className={ProductClasses['edit-btn']} onClick={() => handleEdit(helpItem.helpId)}>
                                            EDIT
                                        </button>
                                    </div>
                                    <Collapse isOpened={expandedItems[index]} theme={{ collapse: HelpClasses["help-content"] }}>
                                    {helpItem.solutions && Array.isArray(helpItem.solutions) ? (
                                        helpItem.solutions.map((solution, solutionIndex) => (
                                            <div key={solutionIndex} data-aos="fade-up"
                                            data-aos-anchor-placement="top-bottom">
                                                <div>
                                                    {/* Check if this is the new entry being added */}
                                                    {(solution.isNew && newlyAddedFields.some(field => field.helpIndex === index && field.solutionIndex === solutionIndex)) ? (
                                                        <div>
                                                            <TextField
                                                                name={`question-${solutionIndex}`}
                                                                label="Question"
                                                                value={solution.Question}
                                                                variant="outlined"
                                                                fullWidth
                                                                className={HelpClasses["help-question-input"]}
                                                                style={{ margin: '10px' }}
                                                                onChange={(e) => handleInputChange(index, solutionIndex, 'Question', e.target.value)}
                                                            />
                                                            <TextField
                                                                name={`answer-${solutionIndex}`}
                                                                label="Answer"
                                                                value={solution.Answer}
                                                                variant="outlined"
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                                className={HelpClasses["help-answer-input"]}
                                                                style={{ margin: '10px' }}
                                                                onChange={(e) => handleInputChange(index, solutionIndex, 'Answer', e.target.value)}
                                                            />
                                                            <div className={HelpClasses['sub-combine']} style={{ marginTop: '10px' }}>
                                                                <Button variant="outlined" color="error" onClick={() => handleCancel(index, solutionIndex)}>Cancel</Button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <Typography variant="body1" className={HelpClasses["help-question-new"]} style={{ margin: '10px' }}>
                                                                Question: {solution.Question}
                                                            </Typography>
                                                            <Typography variant="body1" className={HelpClasses["help-answer-new"]} style={{ margin: '10px' }}>
                                                                <span className={HelpClasses['answer-field']}>Answer:</span> {solution.Answer}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div>
                                            <Typography variant="body1" className={HelpClasses["help-question-new"]} style={{ margin: '10px' }}>
                                                Solutions: {helpItem.solutions}
                                            </Typography>
                                        </div>
                                    )}
                                    
                                        <div className={HelpClasses['last-combine']}>
                                            <Button variant="contained" color="primary" onClick={() => handleAdd(index)}>Add</Button>
                                            <Button variant="contained" color="success" onClick={() => handleUpdate(helpItem.helpId, helpItem)} disabled={isSubmitDisabled}>Submit</Button>
                                        </div>
                                    </Collapse>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
        </Layout>
    );
};

export default Help;
