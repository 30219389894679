import Layout from 'Components/Layout';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import { Services } from 'Services';
import { TextField, Button, Grid, Link, Typography } from '@mui/material';
import { Label } from '@mui/icons-material';
import { Success } from 'Util/Toast';
import ViewUserClasses from '../users/viewUsers/ViewUsers.module.css'
import AddUserClasses from '../users/addUser/AddUser.module.css'
import { Breadcrumbs, Stack } from '@mui/material';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FaqClasses from '../faq/Faq.module.css';




const EditFaq = () => {
    const navigate = useNavigate();
    const { customerSupportId } = useParams();
    const [faq, setFaq] = useState(null);
    const [updatedQuestion, setUpdatedQuestion] = useState('');
    const [updatedAnswer, setUpdatedAnswer] = useState('');

    useEffect(() => {
        const fetchFaq = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.getFAQById(customerSupportId, token);
                setFaq(response.FAQ); // Assuming the response contains the FAQ data
                setUpdatedQuestion(response.FAQ.question);
                setUpdatedAnswer(response.FAQ.answer);
            } catch (error) {
                console.error('Error fetching FAQ:', error);
            }
        };

        fetchFaq();
    }, [customerSupportId]);

    const handleSubmit = async () => {
        let body = {
            question: updatedQuestion,
            answer: updatedAnswer,

        }
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.updateFAQ(customerSupportId, JSON.stringify(body), token);
            // Optionally, you can show a success message or navigate to another page
            navigate(-1); // Navigate back to the previous page after successful update
            Success(response.Message)
        } catch (error) {
            console.error('Error updating FAQ:', error);
            // Optionally, you can show an error message to the user
        }
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/faq')}>
            FAQs
        </Link>,
        <Typography key="2" color="text.primary">
            Add FAQs
        </Typography>,
    ];

    return (
        <Layout Active={'FAQs'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit FAQs</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={AddUserClasses['new-main-config-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                    <div className={AddUserClasses['inside-new-main-btn']}>
                        <button style={{ width: '130px' }} className={FaqClasses['add-button']} onClick={handleSubmit}>
                            Save Changes
                        </button>
                    </div>
                </div>
                <div style={{marginTop: '40px'}} className={FaqClasses['create-main-head']} data-aos="zoom-in-down">
                    <div className={FaqClasses['main-h3-head']}>
                        <h3 className={FaqClasses['main-header']}>Edit FAQs</h3>
                    </div>
                    {faq ? (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Label>Question</Label>
                                <TextField
                                    fullWidth
                                    label="Question"
                                    value={updatedQuestion}
                                    variant="outlined"
                                    onChange={(e) => setUpdatedQuestion(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Label>Answer</Label>
                                <TextField
                                    fullWidth
                                    label="Answer"
                                    value={updatedAnswer}
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    onChange={(e) => setUpdatedAnswer(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default EditFaq;
