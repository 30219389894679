import React, { useState } from 'react';
import {
    Button,
    Grid,
    Typography,
    TextField,
    Link,
    Breadcrumbs,
    Stack,
    FormControl,
    FormHelperText
} from '@mui/material';
import { BiPlus } from 'react-icons/bi';
import { HiOutlineTrash } from "react-icons/hi";
import { Helmet } from 'react-helmet';
import Layout from 'Components/Layout';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import CommonClasses from '../../../Styles/Common.module.css';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import AddOrderClasses from '../../orders/addOrder/AddOrder.module.css';
import ProductSelectionDialog from './ProductSelectionDialog'; // Import the ProductSelectionDialog component
import { Services } from 'Services';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import DealsEndingClasses from './DealsEndingSoon.module.css'
import EditUserClasses from '../../users/editUser/EditUser.module.css'
import { MdOutlineArrowBackIos } from 'react-icons/md';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';
import { Success } from 'Util/Toast';

const CreateDealsEndingSoon = ({ userData, setUserData }) => {
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectedProductDetails, setSelectedProductDetails] = useState([]);
    const [dealStartDate, setDealStartDate] = useState(null); // State for deal start date
    const [dealEndDate, setDealEndDate] = useState(null); // State for deal end date
    const [startDateError, setStartDateError] = useState(false); // State for start date error
    const [endDateError, setEndDateError] = useState(false); // State for end date error

    const navigate = useNavigate();

    const handleCustomerDialogOpen = () => {
        setOpenCustomerDialog(true);
    };

    const handleCustomerDialogClose = () => {
        setOpenCustomerDialog(false);
    };

    const handleAddCustomer = async () => {
        const token = { Authorization: `token ${localStorage.getItem('token')}` };
        try {
            const productDetailsPromises = selectedCustomers.map(customerId =>
                Services.getProductDetails(customerId, token)
            );
            const productDetailsResponses = await Promise.all(productDetailsPromises);

            const validProducts = productDetailsResponses
                .filter(response => response.Status === 1)
                .map(response => response.product);

            setSelectedProductDetails(validProducts);
        } catch (error) {
            console.error('Error fetching user details:', error);
            setSelectedProductDetails([]);
        }
        setOpenCustomerDialog(false);
    };

    const handleDeleteProduct = (productId) => {
        setSelectedProductDetails(prevDetails =>
            prevDetails.filter(product => product.productId !== productId)
        );
    };

    // Handler for deal start date change
    const handleDealStartDateChange = (date) => {
        setDealStartDate(date);
        if (!date) {
            setStartDateError(true);
        } else {
            setStartDateError(false);
        }
    };

    // Handler for deal end date change
    const handleDealEndDateChange = (date) => {
        setDealEndDate(date);
        if (!date) {
            setEndDateError(true);
        } else {
            setEndDateError(false);
        }
    };

    // Handler for save changes button click
    const handleSaveChanges = async () => {
        const token = { Authorization: `token ${localStorage.getItem('token')}` };

        // Validate fields before saving
        if (!dealStartDate) {
            setStartDateError(true);
        }
        if (!dealEndDate) {
            setEndDateError(true);
        }
        if (!dealStartDate || !dealEndDate || selectedProductDetails.length === 0) {
            return;
        }

        const dealData = {
            dealStartDate: dealStartDate,
            dealsEndDate: dealEndDate,
            products: selectedProductDetails.map(product => product.productId)
        };

        try {
            const response = await Services.createDealEndingSoon(dealData, token);
            if (response.Status === 1) {
                navigate('/dealsendingsoon');
                Success(response.Message);
            } else {
                console.error('Failed to create deal:', response.Message);
            }
        } catch (error) {
            console.error('Error creating deal:', error);
        }
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/dealsendingsoon')}>
            Deals Ending Soon
        </Link>,
        <Typography key="3" color="text.primary">
            Create Deals Ending Soon
        </Typography>,
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Layout Active={'Deals Ending Soon'}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Create Deals Ending Soon</title>
                </Helmet>
                <div className={AddUserClasses['main-of-tables']}>
                    <div className={AddUserClasses['new-main-div']}>
                        <div className={ViewUserClasses['main-on-ctrl-btns']}>
                            <div className={CommonClasses['control-btns']}>
                                <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                            </div>
                            <Stack spacing={2}>
                                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </div>
                    </div>
                    <div className={AddUserClasses['main-of-edit-tables']}>
                        <div className={EditUserClasses['main-editsave-btn']}>
                            <button className={EditUserClasses['sub-cancel-btn']} onClick={() => navigate('/dealsendingsoon')} style={{ cursor: 'pointer' }}>
                                Cancel
                            </button>
                            <button className={EditUserClasses['sub-save-btn']} onClick={handleSaveChanges} style={{ cursor: 'pointer' }}>
                                Save Changes
                            </button>
                        </div>
                        <div className={DealsEndingClasses['main-deals-create-ending']}>
                            <div className={AddUserClasses['main-select-order-cust']}>
                                <div className={AddUserClasses['main-user-address']}>
                                    <div className={AddUserClasses['main-userleft-address']}>
                                        <h3 className={AddUserClasses['main-address-header']}>Create Deals Ending Soon</h3>
                                    </div>
                                    <div className={AddUserClasses['main-userright-address']}>
                                        <div className={CommonClasses['control-btns']}>
                                            <Button
                                                style={{ backgroundColor: 'white' }}
                                                className={CommonClasses['button-for-add']}
                                                onClick={handleCustomerDialogOpen}
                                            >
                                                <BiPlus size={20} /> Add Products
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <ProductSelectionDialog
                                    open={openCustomerDialog}
                                    onClose={handleCustomerDialogClose}
                                    onAddProducts={handleAddCustomer}
                                    selectedCustomers={selectedCustomers}
                                    setSelectedCustomers={setSelectedCustomers}
                                />

                                <Grid container spacing={2} alignItems="center" className={DealsEndingClasses['date-pickers-container']}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth error={startDateError}>
                                            <DatePicker
                                                label="Deal Start Date"
                                                value={dealStartDate}
                                                onChange={handleDealStartDateChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        variant="outlined"
                                                        margin="dense"
                                                        error={startDateError}
                                                        helperText={startDateError ? 'Please select a start date' : ''}
                                                        className={AddUserClasses['table-textfield-all']}
                                                    />
                                                )}
                                                inputFormat="MM/dd/yyyy"
                                                adapter={AdapterDayjs}
                                            />
                                            {startDateError && <FormHelperText error>Please select a start date</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth error={endDateError}>
                                            <DatePicker
                                                label="Deal End Date"
                                                value={dealEndDate}
                                                onChange={handleDealEndDateChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        variant="outlined"
                                                        margin="dense"
                                                        error={endDateError}
                                                        helperText={endDateError ? 'Please select an end date' : ''}
                                                        className={AddUserClasses['table-textfield-all']}
                                                    />
                                                )}
                                                inputFormat="MM/dd/yyyy"
                                                adapter={AdapterDayjs}
                                            />
                                            {endDateError && <FormHelperText error>Please select an end date</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <div className={AddOrderClasses['selected-product-details']} style={{ margin: '20px' }}>
                                    {selectedProductDetails.length > 0 && (
                                        <div>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Product ID</th>
                                                        <th>Product</th>
                                                        <th>Discount Price (per unit)</th>
                                                        <th>Product Price</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectedProductDetails.map(product => (
                                                        <tr key={product.productId}>
                                                            <td>{product.productId}</td>
                                                            <td>
                                                                <div className={AddOrderClasses['product-cell']}>
                                                                    <img
                                                                        src={product.productImage[0]}
                                                                        alt={'no img'}
                                                                        className={AddOrderClasses['product-image']}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>{product.productDiscountPrice}</td>
                                                            <td>{product.productPrice}</td>
                                                            <td>
                                                                <button
                                                                    className={AddOrderClasses['delete-order-product']}
                                                                    onClick={() => handleDeleteProduct(product.productId)}
                                                                >
                                                                    <HiOutlineTrash />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {selectedProductDetails.length === 0 && (
                                        <div className={DealsEndingClasses['error-message']}>
                                            Please select at least one product to continue.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </LocalizationProvider>
    );
};

export default CreateDealsEndingSoon;
