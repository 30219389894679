import React from 'react';
import { IconButton } from '@mui/material';
import { BiPlus, BiMinus } from 'react-icons/bi';

const Counter = ({ value, onIncrease, onDecrease }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={onDecrease}>
                <BiMinus />
            </IconButton>
            <span>{value}</span>
            <IconButton onClick={onIncrease}>
                <BiPlus />
            </IconButton>
        </div>
    );
};

export default Counter;
