import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, Button, Box, Typography,
    IconButton, TextField, Checkbox
} from '@mui/material';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { BiPlus, BiSearch } from 'react-icons/bi';
import CloseIcon from '@mui/icons-material/Close';
import styles from './AddOrder.module.css';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import CommonClasses from '../../../Styles/Common.module.css';
import { Services } from 'Services';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import AddOrderClasses from '../addOrder/AddOrder.module.css';
import Counter from './Counter'; // Import the Counter component
import { HiOutlineTrash } from "react-icons/hi";

const AddOrderProducts = ({ userData, setUserData }) => {
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedProductDetails, setSelectedProductDetails] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [isProductsSelected, setIsProductsSelected] = useState(true); // State to manage product selection
    const [subtotal, setSubtotal] = useState(0); // State for subtotal
    const [grandTotal, setGrandTotal] = useState(0); // State for grand total

    useEffect(() => {
        if (openCustomerDialog) {
            const fetchProducts = async () => {
                try {
                    const token = { Authorization: `token ${localStorage.getItem('token')}` };
                    const response = await Services.getAllProducts(token,1);
                    if (response.Status === 1) {
                        const productsWithImages = response.products.map(product => ({
                            ...product,
                            productImage: product.productImage.map(image => ({
                                productImage: image // Assuming productImage is already an array of image URLs
                            }))
                        }));
                        setProducts(productsWithImages);
                    } else {
                        console.error('Failed to fetch products:', response.Message);
                        setProducts([]);
                    }
                } catch (error) {
                    console.error('Error fetching products:', error);
                    setProducts([]);
                }
            };
            fetchProducts();
        }
    }, [openCustomerDialog]);

    const handleCustomerDialogOpen = () => {
        setOpenCustomerDialog(true);
    };

    const handleCustomerDialogClose = () => {
        setOpenCustomerDialog(false);
    };

    const handleCustomerChange = (id) => {
        setSelectedCustomers(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(customerId => customerId !== id)
                : [...prevSelected, id]
        );
    };

    const handleAddCustomer = async () => {
        const token = { Authorization: `token ${localStorage.getItem('token')}` };
        try {
            const productDetailsPromises = selectedCustomers.map(customerId =>
                Services.getProductDetails(customerId, token)
            );
            const productDetailsResponses = await Promise.all(productDetailsPromises);

            const validProducts = productDetailsResponses
                .filter(response => response.Status === 1)
                .map(response => response.product);

            setSelectedProductDetails(validProducts);
            setQuantities(validProducts.reduce((acc, product) => {
                acc[product.productId] = 1;
                return acc;
            }, {}));

            // Update userData with selected product details and quantities
            setUserData(prevUserData => ({
                ...prevUserData,
                selectedProductDetails: validProducts,
                quantities: validProducts.reduce((acc, product) => {
                    acc[product.productId] = 1;
                    return acc;
                }, {})
            }));

            // Set isProductsSelected to true if products are selected
            setIsProductsSelected(true);
        } catch (error) {
            console.error('Error fetching user details:', error);
            setSelectedProductDetails([]);
            setIsProductsSelected(false);
        }
        setOpenCustomerDialog(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleIncreaseQuantity = (productId) => {
        const newQuantity = quantities[productId] + 1;
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: newQuantity
        }));

        // Update userData with the new quantity value
        setUserData(prevUserData => ({
            ...prevUserData,
            quantities: {
                ...prevUserData.quantities,
                [productId]: newQuantity
            }
        }));
    };

    const handleDecreaseQuantity = (productId) => {
        const newQuantity = Math.max(1, quantities[productId] - 1);
        setQuantities(prevQuantities => ({
            ...prevQuantities,
            [productId]: newQuantity
        }));

        // Update userData with the new quantity value
        setUserData(prevUserData => ({
            ...prevUserData,
            quantities: {
                ...prevUserData.quantities,
                [productId]: newQuantity
            }
        }));
    };

    const handleDeleteProduct = (productId) => {
        setSelectedProductDetails(prevDetails =>
            prevDetails.filter(product => product.productId !== productId)
        );
        setQuantities(prevQuantities => {
            const newQuantities = { ...prevQuantities };
            delete newQuantities[productId];
            return newQuantities;
        });
    };

    // Function to map productCategoryId to category names
    const mapCategoryIdToName = (categoryId) => {
        switch (categoryId) {
            case 1:
                return 'Medical';
            case 2:
                return 'Surgical';
            case 3:
                return 'Ayurveda';
            case 4:
                return 'Skin care';
            default:
                return '';
        }
    };

    // Calculate the total product price for each product
    const calculateTotalProductPrice = (productId) => {
        if (quantities[productId] && selectedProductDetails.length > 0) {
            const product = selectedProductDetails.find(p => p.productId === productId);
            return product.productPrice * quantities[productId];
        }
        return 0;
    };

    // Calculate Subtotal and Grand Total
useEffect(() => {
    // Calculate subtotal
    const subtotalValue = selectedProductDetails.reduce((total, product) => {
        const totalPrice = product.productPrice * quantities[product.productId];
        return total + totalPrice;
    }, 0);
    setSubtotal(subtotalValue);

    // Calculate grand total (total discount price)
    const grandTotalValue = selectedProductDetails.reduce((total, product) => {
        const discountedPrice = product.productDiscountPrice * quantities[product.productId];
        return total + discountedPrice;
    }, 0);
    setGrandTotal(grandTotalValue);

    // Update userData with the grand total and subtotal
    setUserData(prevUserData => ({
        ...prevUserData,
        grandTotal: grandTotalValue,
        subtotal: subtotalValue
    }));
}, [selectedProductDetails, quantities]);


    const columns = [
        {
            field: 'checkbox',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                <Checkbox
                    checked={selectedCustomers.includes(params.id)}
                    onChange={() => handleCustomerChange(params.id)}
                    value={params.id}
                />
            ),
        },
        {
            field: 'productImage',
            headerName: 'Images',
            width: 150,
            renderCell: (params) => (
                <div>
                    {params.row.productImage.slice(0, 1).map((image, index) => (
                        <img key={index} src={image.productImage} alt={`Product ${index + 1}`} style={{ width: '50px', height: '50px', margin: '2px' }} />
                    ))}
                </div>
            ),
        },
        { field: 'productName', headerName: 'Name', width: 150 },
        {
            field: 'categoryName',
            headerName: 'Category',
            width: 200,
        },
        { field: 'stockQty', headerName: 'Stock', width: 150 },
        { field: 'productDiscountPrice', headerName: 'Selling Price', width: 150 },
        { field: 'manufacturer', headerName: 'Manufacturer', width: 150 },
        {
            field: 'productPrice',
            headerName: 'Total Product Price',
            width: 200,
        },
    ];

    const filteredProducts = products.filter(product =>
        (product.productName && product.productName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (product.manufacturer && product.manufacturer.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    return (
        <div className={AddUserClasses['main-select-order-cust']}>
            <div className={AddUserClasses['main-user-address']}>
                <div className={AddUserClasses['main-userleft-address']}>
                    <h3 className={AddUserClasses['main-address-header']}>Products</h3>
                </div>
                <div className={AddUserClasses['main-userright-address']}>
                    <div className={CommonClasses['control-btns']}>
                        <Button
                            style={{ backgroundColor: 'white' }}
                            className={CommonClasses['button-for-add']}
                            onClick={handleCustomerDialogOpen}
                        >
                            <BiPlus size={20} /> Add Products
                        </Button>
                    </div>
                </div>

                <Dialog open={openCustomerDialog} onClose={handleCustomerDialogClose} maxWidth="sm" fullWidth>
                    <DialogTitle className={styles['dialog-title']}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6">Add Product(s)</Typography>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    placeholder="Product name"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    InputProps={{
                                        startAdornment: <BiSearch />,
                                    }}
                                    style={{ marginRight: 8 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginRight: 8, color: 'white', backgroundColor: '#23232a' }}
                                    onClick={handleAddCustomer}
                                >
                                    Add
                                </Button>
                                <IconButton onClick={handleCustomerDialogClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <div className={styles['table-container']}>
                            <DataGrid
                                rows={filteredProducts}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => row.productId}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            <div className={AddOrderClasses['selected-product-details']}>
                {selectedProductDetails.length > 0 && (
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Product ID</th>
                                    <th>Product</th>
                                    <th style={{ paddingLeft: '45px' }}>Qty</th>
                                    <th>Price with Discount (per unit)</th>
                                    <th>Total Price with Discount</th>
                                    <th>Price without Discount</th>
                                    <th>Total Price without Discount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedProductDetails.map(product => {
                                    const totalPrice = product.productDiscountPrice * quantities[product.productId];
                                    return (
                                        <tr key={product.productId}>
                                            <td>{product.productId}</td>
                                            <td>
                                                <div className={AddOrderClasses['product-cell']}>
                                                    <img
                                                        src={product.productImage[0]}
                                                        alt={'no img'}
                                                        className={AddOrderClasses['product-image']}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <Counter
                                                    value={quantities[product.productId]}
                                                    onIncrease={() => handleIncreaseQuantity(product.productId)}
                                                    onDecrease={() => handleDecreaseQuantity(product.productId)}
                                                />
                                            </td>
                                            <td>{product.productDiscountPrice}</td>
                                            <td>{totalPrice}</td>
                                            <td>{product.productPrice}</td>
                                            <td>{calculateTotalProductPrice(product.productId)}</td>
                                            <td>
                                                <button
                                                    className={AddOrderClasses['delete-order-product']}
                                                    onClick={() => handleDeleteProduct(product.productId)}
                                                >
                                                    <HiOutlineTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td colSpan="4"></td>
                                    <td><strong>Grand Total: ${grandTotal.toFixed(2)}</strong></td>
                                    <td><strong>Subtotal: ${subtotal.toFixed(2)}</strong></td>
                                    <td colSpan="2"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
                {!isProductsSelected && (
                    <div className={AddOrderClasses['error-message']}>
                        Please select at least one product to continue.
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddOrderProducts;
