import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, IconButton, Popover, Typography } from '@mui/material';
import { HiOutlineTrash } from 'react-icons/hi';
import { BiPlus } from 'react-icons/bi';
import { Services } from 'Services';
import { Success, Error } from 'Util/Toast';
import { DataGrid } from '@mui/x-data-grid';
import Counter from '../../orders/addOrder/Counter';
import CommonClasses from '../../../Styles/Common.module.css';
import OrderClasses from '../Orders.module.css';
import AddProductsDialog from './AddProductsDialog';
import styles from '../editOrder/EditOrder.module.css';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import EditUserClasses from '../../users/editUser/EditUser.module.css';
import EditOrderClasses from './EditOrder.module.css';
import { Hourglass } from 'react-loader-spinner';

const EditOrderProducts = () => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [quantities, setQuantities] = useState({});
    const [subTotal, setSubTotal] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deletePopoverAnchorEl, setDeletePopoverAnchorEl] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    // Function to simulate a delay
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getOrderDetails(orderId, token);
                if (response.Status === 1) {
                    await delay(2000); // 2-second delay for illustration
                    const orderWithIds = {
                        ...response.order,
                        products: response.order.products.map((product, index) => ({
                            ...product,
                            id: index + 1,
                        })),
                    };
                    setOrder(orderWithIds);
                    const initialQuantities = orderWithIds.products.reduce((acc, product) => {
                        acc[product.productId] = product.qty;
                        return acc;
                    }, {});
                    setQuantities(initialQuantities);
                } else {
                    setError(response.Message);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [orderId]);

    useEffect(() => {
        if (order) {
            const totalWithoutDiscount = order.products.reduce((sum, product) => {
                const qty = quantities[product.productId] || 1;
                return sum + product.productPrice * qty;
            }, 0);
            setSubTotal(totalWithoutDiscount);

            const totalWithDiscount = order.products.reduce((sum, product) => {
                const qty = quantities[product.productId] || 1;
                return sum + product.productDiscountPrice * qty;
            }, 0);
            setGrandTotal(totalWithDiscount);
        }
    }, [order, quantities]);

    const handleIncreaseQuantity = (productId) => {
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [productId]: (prevQuantities[productId] || 1) + 1,
        }));
    };

    const handleDecreaseQuantity = (productId) => {
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [productId]: Math.max(1, (prevQuantities[productId] || 1) - 1),
        }));
    };

    const handleDeleteProduct = (productId) => {
        setOrder((prevOrder) => ({
            ...prevOrder,
            products: prevOrder.products.filter((product) => product.productId !== productId),
        }));
        setQuantities((prevQuantities) => {
            const newQuantities = { ...prevQuantities };
            delete newQuantities[productId];
            return newQuantities;
        });
    };

    const handleAddProductsClick = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleAddProducts = (selectedProducts) => {
        setOrder((prevOrder) => ({
            ...prevOrder,
            products: [
                ...prevOrder.products,
                ...selectedProducts.map((product, index) => ({
                    ...product,
                    id: prevOrder.products.length + index + 1,
                })),
            ],
        }));
        const newQuantities = selectedProducts.reduce((acc, product) => {
            acc[product.productId] = 1;
            return acc;
        }, {});
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            ...newQuantities,
        }));
    };

    const handleSave = async () => {
        if (order) {
            const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
            const updatedProducts = order.products.map((product) => ({
                productId: product.productId,
                qty: quantities[product.productId],
                productPrice: product.productPrice,
                productDiscountPrice: product.productDiscountPrice,
            }));
    
            const payload = {
                ...order,
                products: updatedProducts,
                subTotal: subTotal,
                grandTotal: grandTotal,
            };
    
            try {
                const response = await Services.editOrder(orderId, payload, token);
                if (response.Status === 1) {
                    Success('Order updated successfully');
                } else {
                    Error(response.Message);
                }
            } catch (err) {
                Error('Error updating order');
            }
        }
    };
    

    const handleDeletePopoverOpen = (event) => {
        setDeletePopoverAnchorEl(event.currentTarget);
    };

    const handleDeletePopoverClose = () => {
        setDeletePopoverAnchorEl(null);
    };

    const deletePopoverOpen = Boolean(deletePopoverAnchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const popoverOpen = Boolean(anchorEl);

    if (loading) {
        return (
            <div className={EditOrderClasses['spinner-container']}>
                <Hourglass
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="hourglass-loading"
                    colors={['#23232A', '#60E19B']}
                />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!order) {
        return <div>No order found.</div>;
    }

    const columns = [
        { field: 'productId', headerName: 'Product Id', width: 200 },
        {
            field: 'productImage',
            headerName: 'Product Image',
            width: 200,
            renderCell: (params) => (
                <div>
                    <a href={params.row.productImage[0]} target="_blank" rel="noopener noreferrer">
                        <img
                            src={params.row.productImage[0]}
                            alt={params.row.productName}
                            className={EditOrderClasses['ordered-prod-imgs']}
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        />
                    </a>
                    <Popover
                        id="mouse-over-popover"
                        sx={{ pointerEvents: 'none' }}
                        open={popoverOpen}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <Typography className={EditUserClasses['popover-radio']} sx={{ p: 1 }}>View Image</Typography>
                    </Popover>
                </div>
            ),
        },
        {
            field: 'qty',
            headerName: 'Quantity',
            width: 200,
            renderCell: (params) => (
                <Counter
                    value={params.value}
                    onIncrease={() => handleIncreaseQuantity(params.row.productId)}
                    onDecrease={() => handleDecreaseQuantity(params.row.productId)}
                />
            ),
        },
        { field: 'productPrice', headerName: 'Product Price without discount', width: 300 },
        { field: 'productDiscountPrice', headerName: 'Product Price with discount', width: 300 },
        { field: 'totalPriceWithoutDiscount', headerName: 'Total Price Without Discount', width: 300 },
        { field: 'totalPriceWithDiscount', headerName: 'Total Price With Discount', width: 300 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <div>
                    <IconButton
                        onClick={() => handleDeleteProduct(params.row.productId)}
                        onMouseEnter={handleDeletePopoverOpen}
                        onMouseLeave={handleDeletePopoverClose}
                    >
                        <HiOutlineTrash />
                    </IconButton>
                    <Popover
                        id="delete-popover"
                        sx={{ pointerEvents: 'none', boxShadow: 'none', }}
                        open={deletePopoverOpen}
                        anchorEl={deletePopoverAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handleDeletePopoverClose}
                        disableRestoreFocus
                    >
                        <Typography className={EditUserClasses['popover-radio']} sx={{ p: 1 }}>
                            Remove product.
                        </Typography>
                    </Popover>
                </div>
            ),
        },
    ];

    return (
        <div>
            <div className={styles['main-handle-over-btn']}>
                <button className={styles['general-savechanges']} onClick={handleSave}>
                    Save Changes
                </button>
            </div>
            <div className={styles['edit-order-products']}>
                <div>
                    <div className={AddUserClasses['main-address-userright-address']}>
                        <div>
                            <h4 className={AddUserClasses['prod-right-address-h4']}>Products</h4>
                        </div>
                        <div className={CommonClasses['control-btns']}>
                            <Button
                                style={{ backgroundColor: 'white' }}
                                className={CommonClasses['button-for-add']}
                                onClick={handleAddProductsClick}
                            >
                                <BiPlus size={20} /> Add Products
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={CommonClasses['Table']} style={{ maxHeight: 600 }}>
                    <DataGrid
                        rows={order.products.map((product) => ({
                            ...product,
                            qty: quantities[product.productId] || 1,
                            totalPriceWithoutDiscount: (quantities[product.productId] || 1) * product.productPrice,
                            totalPriceWithDiscount: (quantities[product.productId] || 1) * product.productDiscountPrice,
                        }))}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                        getRowId={(row) => row.id}
                    />
                </div>
                <div className={styles['total-container']}>
                    <h4><span>Sub Total: </span>₹{subTotal}</h4>
                    <h4><span>Grand Total: </span>₹{grandTotal}</h4>
                </div>
                <AddProductsDialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    onAddProducts={handleAddProducts}
                />
            </div>
        </div>
    );
};

export default EditOrderProducts;
