import React from 'react';
import { TextField } from '@mui/material';
import AddUserClasses from '../../users/addUser/AddUser.module.css';

const AddProductDescription = ({ userData, setUserData, errors, validate }) => {
    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData(prevUserData => ({
            ...prevUserData,
            [name]: value
        }));

        // Remove error when user starts typing
        validate(name, value);
    };

    return (
        <div>
            <table className={AddUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={AddUserClasses['table-header']}>Basic Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={AddUserClasses['table-label']}>Description</td>
                        <td className={AddUserClasses['table-value']}>
                            <TextField
                                required
                                id="productDescription"
                                name="productDescription"
                                value={userData.productDescription || ''}
                                onChange={handleChange}
                                variant="outlined"
                                margin="dense"
                                multiline
                                fullWidth
                                rows={4}
                                className={AddUserClasses['table-textfield-all']}
                                error={!!errors.productDescription}
                                helperText={errors.productDescription}
                                placeholder='Product Description *'
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={AddUserClasses['table-label']}>Product Use</td>
                        <td className={AddUserClasses['table-value']}>
                            <TextField
                                required
                                id="productUse"
                                name="productUse"
                                value={userData.productUse || ''}
                                onChange={handleChange}
                                variant="outlined"
                                margin="dense"
                                multiline
                                fullWidth
                                rows={4}
                                className={AddUserClasses['table-textfield-all']}
                                error={!!errors.productUse}
                                helperText={errors.productUse}
                                placeholder='Product Use *'
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={AddUserClasses['table-label']}>Product Benefits</td>
                        <td className={AddUserClasses['table-value']}>
                            <TextField
                                required
                                id="productBenefits"
                                name="productBenefits"
                                value={userData.productBenefits || ''}
                                onChange={handleChange}
                                variant="outlined"
                                margin="dense"
                                multiline
                                fullWidth
                                rows={4}
                                className={AddUserClasses['table-textfield-all']}
                                error={!!errors.productBenefits}
                                helperText={errors.productBenefits}
                                placeholder='Product Benifits *'
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default AddProductDescription;
