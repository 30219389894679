import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Breadcrumbs, Link, Typography, Button, Stack, Tabs, Tab, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Layout from 'Components/Layout';
import CommonClasses from 'Styles/Common.module.css';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import AddUserClasses from '../../users/addUser/AddUser.module.css'
import ProductOverview from './ProductOverview';
import ProductDescription from './ProductDescription';
import ProductGallery from './ProductGallery';
import ProductPricing from './ProductPricing';
import { Services } from 'Services';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const ViewProducts = () => {
    const navigate = useNavigate();
    const { userId } = useParams();
    const { state } = useLocation();
    const user = state?.user || {};
    const [tabIndex, setTabIndex] = useState(0);
    const { productId } = useParams(); // Fixing the useParams hook usage
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [product, setProduct] = useState(null); // Single product


    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/users')}>
            Products
        </Link>,
        <Typography key="3" color="text.primary">
            View Product
        </Typography>,
    ];

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getProductDetails(productId, token);
                setProduct(response.product); // Assuming response contains a single product object
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [productId]);

    return (
        <Layout Active={'Products'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Products</title>
            </Helmet>
            <div className={AddUserClasses['main-of-tables']}>
                <div className={AddUserClasses['new-main-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>

                <div>
                    <div className={ViewUserClasses['main-name']}>
                        {loading ? (
                            <p>Loading...</p>
                        ) : error ? (
                            <p>{error}</p>
                        ) : product ? (
                            <div className={ViewUserClasses['main-name']}>
                                <div className={ViewUserClasses['inside-leftname']}>
                                    <h1 className={ViewUserClasses['user-fullname']}>{product.productName}</h1>
                                    <h1 className={ViewUserClasses['user-fullname']}>{product.metricValue}{product.metricType}</h1>
                                </div>
                            </div>
                        ) : (
                            <p>No product found</p>
                        )}
                        <div className={ViewUserClasses['inside-rightname']}>
                            <button onClick={() => navigate(`/edit-product/${productId}`)}>Edit</button>
                        </div>
                    </div>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <Tabs className={ViewUserClasses['tabs-main-head']} value={tabIndex} onChange={handleTabChange} aria-label="user details tabs">
                            <Tab className={ViewUserClasses['tabs-main-sub-head']} label="Overview" />
                            <Tab label="Description" />
                            <Tab label="Gallery" />
                            <Tab label="Pricing" />
                        </Tabs>
                        <TabPanel value={tabIndex} index={0}>
                            <ProductOverview user={user} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <ProductDescription user={user} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={2}>
                            <ProductGallery user={user} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={3}>
                            <ProductPricing user={user} />
                        </TabPanel>
                    </Box>
                </div>
            </div>
        </Layout>
    );
};

export default ViewProducts;
