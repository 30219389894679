import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import { IoIosAdd } from 'react-icons/io';
import { NavLink, useNavigate } from 'react-router-dom';
import AddUserClasses from '../users/addUser/AddUser.module.css';
import UserClasses from '../users/Users.module.css';
import TeamClasses from './AdminSettings.module.css';
import { Services } from 'Services';
import Permissions from './Config';
import { Breadcrumbs, Link, Typography, Button, Stack, Tabs, Tab, Box } from '@mui/material';
import { Success } from 'Util/Toast';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ViewUserClasses from '../users/viewUsers/ViewUsers.module.css';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import CommonClasses from '../../Styles/Common.module.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const CreateTeamMember = () => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        role: 2,
        mobileNumber: '',
        permissions: [...Permissions, { name: 'User Subscriptions', Permissions: { edit: 1, read: 1, write: 1 } }],
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        password: '',
        mobileNumber: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleCheckboxChange = (page, permissionType, checked) => {
        setFormData((prevData) => {
            const selectedPermission = [...prevData.permissions];
            const index = selectedPermission.findIndex((item) => item.name === page);
            selectedPermission[index].Permissions[permissionType] = checked ? 1 : 0;

            return {
                ...prevData,
                permissions: selectedPermission,
            };
        });
    };

    const validateMobileNumber = (mobileNumber) => {
        // Regular expression to validate mobile number format
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobileNumber);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
        const errors = {};
        if (!formData.name) {
            errors.name = 'Name is required';
        }
        if (!formData.email) {
            errors.email = 'Email is required';
        }
        if (!formData.password) {
            errors.password = 'Password is required';
        }
        if (!formData.mobileNumber) {
            errors.mobileNumber = 'Mobile Number is required';
        } else if (!validateMobileNumber(formData.mobileNumber)) {
            errors.mobileNumber = 'Invalid mobile number format';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };

            // Transform permissions
            const response = await Services.createAdminsTeamMember({ ...formData }, token);

            console.log(response);

            if (response.Status === 1) {
                // Clear form data
                setFormData({
                    name: '',
                    email: '',
                    password: '',
                    role: 0,
                    mobileNumber: '',
                    permissions: [...Permissions],
                });

                navigate('/permissions');
                Success(response.Message)
            } else {
                console.error('Error creating Admins Team Member:', response.Message);
            }
        } catch (error) {
            console.error('Error creating Admins Team Member:', error);
        }
    };

    useEffect(() => {
        AOS.init({
            duration: 1000, // Duration of animation
            once: true, // Whether animation should happen only once
            // Add more options as needed
        });
    }, []);

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/permissions')}>
            Role Based Access
        </Link>,
        <Typography key="3" color="text.primary">
            Create Member
        </Typography>,
    ];

    return (
        <Layout Active={'Permissions'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Permissions</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    <div style={{marginLeft: '30px'}} className={AddUserClasses['new-main-div']}>
                        <div className={ViewUserClasses['main-on-ctrl-btns']}>
                            <div className={CommonClasses['control-btns']}>
                                <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                            </div>
                            <Stack spacing={2}>
                                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </div>
                    </div>
                    <div className={UserClasses['Add-users-sub-one']} data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration="500">
                        <div className={UserClasses['form-header-one']}>
                            ADD MEMBER HERE..!
                        </div>
                        <div className={UserClasses['Add-users-sub-two']}>
                            <div className={UserClasses['users-heading-two']}>
                                <form onSubmit={handleSubmit} className={TeamClasses['team-form']}>
                                    <div className={TeamClasses['team-sub-form']}>
                                        <label className={TeamClasses['team-label']}>Name:</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            className={TeamClasses['team-input']}
                                        />
                                        {formErrors.name && <div className={TeamClasses['error-message']}>{formErrors.name}</div>}
                                    </div>
                                    <div className={TeamClasses['team-sub-form']}>
                                        <label className={TeamClasses['team-label']}>Email:</label>
                                        <input
                                            type="text"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            className={TeamClasses['team-input']}
                                        />
                                        {formErrors.email && <div className={TeamClasses['error-message']}>{formErrors.email}</div>}
                                    </div>
                                    <div className={TeamClasses['team-sub-form']}>
                                        <label className={TeamClasses['team-label']}>Password:</label>
                                        <div className={TeamClasses['password-input-container']}>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                className={TeamClasses['team-input']}
                                            />
                                            <span
                                                className={TeamClasses['show-hide-text']}
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? 'Hide' : 'Show'} Password
                                            </span>
                                        </div>
                                        {formErrors.password && <div className={TeamClasses['error-message']}>{formErrors.password}</div>}
                                    </div>
                                    <div className={TeamClasses['team-sub-form']}>
                                        <input
                                            type="hidden"
                                            name="role"
                                            value={formData.role}
                                            onChange={handleInputChange}
                                            className={TeamClasses['team-input']}
                                        />
                                    </div>
                                    <div className={TeamClasses['team-sub-form']}>
                                        <label className={TeamClasses['team-label']}>Mobile Number:</label>
                                        <input
                                            type="text"
                                            name="mobileNumber"
                                            value={formData.mobileNumber}
                                            onChange={handleInputChange}
                                            className={TeamClasses['team-input']}
                                            placeholder="Enter 10-digit mobile number"
                                        />
                                        {formErrors.mobileNumber && <div className={TeamClasses['error-message']}>{formErrors.mobileNumber}</div>}
                                    </div>
                                    <div>
                                        <label className={TeamClasses['team-label']}>Permissions:</label>
                                        <table className={UserClasses['Table']}>
                                            <thead>
                                                <tr>
                                                    <th>Pages</th>
                                                    <th>Read</th>
                                                    <th>Write</th>
                                                    <th>Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formData.permissions.map((item, index) => (
                                                    <tr key={item.name}>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={item.Permissions.read === 1}
                                                                onChange={(e) => handleCheckboxChange(item.name, 'read', e.target.checked)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={item.Permissions.write === 1}
                                                                onChange={(e) => handleCheckboxChange(item.name, 'write', e.target.checked)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={item.Permissions.edit === 1}
                                                                onChange={(e) => handleCheckboxChange(item.name, 'edit', e.target.checked)}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <button className={UserClasses['form-last-btn']} type="submit">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default CreateTeamMember;
