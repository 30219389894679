import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, Button, Box, Typography,
    IconButton, TextField, Checkbox
} from '@mui/material';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { BiSearch } from 'react-icons/bi';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../orders/addOrder/AddOrder.module.css';
import CommonClasses from '../../../Styles/Common.module.css';
import { Services } from 'Services';

const ProductSelectionDialog = ({ open, onClose, onAddProducts, selectedCustomers, setSelectedCustomers }) => {
    const [products, setProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (open) {
            const fetchProducts = async () => {
                try {
                    const token = { Authorization: `token ${localStorage.getItem('token')}` };
                    const response = await Services.getAllProducts(token);
                    if (response.Status === 1) {
                        const productsWithImages = response.products.map(product => ({
                            ...product,
                            productImage: product.productImage.map(image => ({
                                productImage: image
                            }))
                        }));
                        setProducts(productsWithImages);
                    } else {
                        console.error('Failed to fetch products:', response.Message);
                        setProducts([]);
                    }
                } catch (error) {
                    console.error('Error fetching products:', error);
                    setProducts([]);
                }
            };
            fetchProducts();
        }
    }, [open]);

    const handleCustomerChange = (id) => {
        setSelectedCustomers(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(customerId => customerId !== id)
                : [...prevSelected, id]
        );
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const columns = [
        {
            field: 'checkbox',
            headerName: '',
            width: 50,
            renderCell: (params: GridRenderCellParams) => (
                <Checkbox
                    checked={selectedCustomers.includes(params.id)}
                    onChange={() => handleCustomerChange(params.id)}
                    value={params.id}
                />
            ),
        },
        {
            field: 'productImage',
            headerName: 'Images',
            width: 150,
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    {params.row.productImage.slice(0, 1).map((image, index) => (
                        <img key={index} src={image.productImage} alt={`Product ${index + 1}`} style={{ width: '50px', height: '50px', margin: '2px' }} />
                    ))}
                </div>
            ),
        },
        { field: 'productName', headerName: 'Name', width: 150 },
        { field: 'productCategoryId', headerName: 'Category', width: 200 },
        { field: 'stockQty', headerName: 'Stock', width: 150 },
        { field: 'productDiscountPrice', headerName: 'Selling Price', width: 150 },
        { field: 'manufacturer', headerName: 'Manufacturer', width: 150 },
    ];

    const filteredProducts = products.filter(product =>
        (product.productName && product.productName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (product.manufacturer && product.manufacturer.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className={styles['dialog-title']}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Add Product(s)</Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            size="small"
                            variant="outlined"
                            placeholder="Product name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: <BiSearch />,
                            }}
                            style={{ marginRight: 8 }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: 8 }}
                            onClick={onAddProducts}
                        >
                            Add
                        </Button>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <div className={styles['table-container']}>
                    <DataGrid
                        rows={filteredProducts}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        getRowId={(row) => row.productId}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ProductSelectionDialog;
