import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Layout from 'Components/Layout';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import Orderclasses from '../orders/Orders.module.css';
import { Services } from 'Services';
import { Tab, Tabs, Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { BiPlus } from 'react-icons/bi';
import SkeletonLoad from 'Components/TableLoad/load';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Success } from 'Util/Toast';
import moment from 'moment';

const Coupons = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [coupons, setCoupons] = useState([]);
    const [selectedCoupons, setSelectedCoupons] = useState([]);
    const [selectedCouponCodeID, setSelectedCouponCodeID] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        getCouponsByStatus(activeTab + 1);
    }, [activeTab]);

    const getCouponsByStatus = (status) => {
        const token = { Authorization: `token ${localStorage.getItem('token')}` };
        Services.getCouponsByStatus(status, token)
            .then((response) => {
                const couponsWithIds = response.coupons.map(coupon => ({ ...coupon, id: coupon.couponCodeID }));
                setCoupons(couponsWithIds);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    // const handleRowClick = (params) => {
    //     navigate(`/view-coupon/${params.row.couponCodeID}`, { state: { activeTab } });
    // };

    const handleEditCoupon = (event, couponCodeID) => {
        event.stopPropagation();
        navigate(`/editcoupon/${selectedCouponCodeID}`);
    };

    const handleMenuOpen = (event, couponCodeID) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setSelectedCouponCodeID(couponCodeID);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true);
        handleMenuClose();
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteCoupon = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            await Services.deleteCoupon(selectedCouponCodeID, token);
            setCoupons(coupons.filter(coupon => coupon.id !== selectedCouponCodeID));
            handleCloseDeleteDialog();
            Success('Coupon Deleted Successfully');
        } catch (error) {
            console.error('Error deleting coupon:', error);
        }
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedCoupons(newSelection);
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD MMM YYYY');
    };

    const formatCouponValue = (params) => {
        return `${params.value}%`;
    };

    const columns = [
        { field: 'couponCodeID', headerName: 'Coupon Id', width: 150 },
        {
            field: 'image',
            headerName: 'Coupon Image',
            width: 200,
            renderCell: (params) => (
                <img
                    src={params.row.image || 'https://via.placeholder.com/100'}
                    alt="Coupon"
                    style={{ width: '100px', height: '30px', marginTop: '10px', marginBottom: '20px' }}
                />
            ),
        },
        { field: 'couponCode', headerName: 'Coupon Code', width: 200 },
        { field: 'offerValue', headerName: 'Coupon Amount', width: 200 },
        { field: 'description', headerName: 'Description', width: 200 },
        { field: 'createdAt', headerName: 'Date Created', width: 200, valueFormatter: (params) => formatDate(params) },
        { field: 'endDate', headerName: 'Expiry Date', width: 200, valueFormatter: (params) => formatDate(params) },
        { field: 'maxDiscount', headerName: 'Discount', width: 200, renderCell: (params) => formatCouponValue(params) },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 130,
            renderCell: (params) => (
                <div>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuOpen(event, params.row.couponCodeID)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                        sx={{ boxShadow: 'none' }}
                    >
                        <MenuItem onClick={(event) => handleEditCoupon(event, params.row.couponCodeID)}>Edit</MenuItem>
                        <MenuItem onClick={handleOpenDeleteDialog}>Delete</MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    return (
        <Layout Active={"Coupons"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Coupons</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <h3 className={ProductClasses['main-header']}>Coupons<span className={ProductClasses['count-of-users']}>({coupons.length})</span></h3>
                <div className={CommonClasses['control-btns']}>
                    <Button style={{ marginBottom: '20px' }}
                        className={CommonClasses['button-for-add']}
                        onClick={() => {
                            navigate("/createcoupon");
                        }}>
                        <BiPlus size={20} /> Add Coupon
                    </Button>
                </div>
                <Paper square className={Orderclasses['paper-square']}>
                    <Tabs
                        value={activeTab}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                        onChange={(event, newValue) => setActiveTab(newValue)}
                    >
                        <Tab label="All" />
                        <Tab label="Active" />
                        <Tab label="Expired" />
                    </Tabs>
                </Paper>
                {loading ? (
                    <SkeletonLoad
                        TableHead={["Coupon ID", "Date Created", "Coupon Name", "Discount"]}
                    />
                ) : (
                    <div className={CommonClasses['Table']} style={{ maxHeight: 600 }}>
                        <DataGrid
                            rows={coupons}
                            columns={columns}
                            pageSize={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                            pagination
                            onPageChange={(newPage) => setRowsPerPage(newPage)}
                            onPageSizeChange={(pageSize) => setRowsPerPage(pageSize)}
                            // onRowClick={handleRowClick}
                            checkboxSelection
                            onSelectionModelChange={handleSelectionModelChange}
                            selectionModel={selectedCoupons}
                        />
                    </div>
                )}
            </div>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Coupon"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this coupon?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteCoupon} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default Coupons;
