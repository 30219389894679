import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import { Services } from 'Services';
import { Success, Error } from 'Util/Toast'; // Assuming you have a Toast utility for notifications
import EditProdClasses from '../../Product/editProduct/EditProduct.module.css';
import EditOrderGeneralClasses from './EditOrder.module.css';
import { Hourglass } from 'react-loader-spinner';

const EditOrderCustomerAndShipping = () => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deliveryAddress, setDeliveryAddress] = useState({
        firstName: '',
        lastName: '',
        company: '',
        flatNo: '',
        area: '',
        city: '',
        state: '',
        pinCode: '',
        mobileNumber: ''
    });

    // Function to simulate a delay
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        const fetchOrderDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getOrderDetails(orderId, token);
                await delay(2000); // 1-second delay
                setOrder(response.order);
                setUser(response.user);
                setDeliveryAddress(response.order.deliveryAddress);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrderDetails();
    }, [orderId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(e.target, 'etarget')
        setOrder(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
console.log(order, 'order')
    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setDeliveryAddress(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = async () => {
        const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
        const payload = {
            ...order,
            // ...user,
            deliveryAddress: JSON.stringify(deliveryAddress)
        };
console.log(payload, 'payload')
        try {
            const response = await Services.editOrder(orderId, payload, token);
            console.log('Order updated successfully', response);
            Success(response.Message);
        } catch (err) {
            console.error('Error updating order', err);
            Error('Error updating order');
        }
    };

    console.log(user, 'user')
    if (loading) {
        return (
            <div className={EditOrderGeneralClasses['spinner-container']}>
                <Hourglass
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="hourglass-loading"
                    colors={['#23232A', '#60E19B']}
                />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!order || !user || !deliveryAddress) {
        return <div>No order found.</div>;
    }

    return (
        <div>
            {order ? (     
                    <div className={EditProdClasses['main-prod-over-btn']}>
                        <button className={EditOrderGeneralClasses['general-savechanges']} onClick={handleSave}>Save Changes</button>
                    </div>
            ) : ''
            }
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>Customer Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>First Name</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                name="firstName"
                                value={order.firstName}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Last Name</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                name="lastName"
                                value={order.lastName}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Company</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                name="company"
                                value={order.company}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Email</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                name="email"
                                value={order.email}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Phone Number</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                name="mobileNumber"
                                value={order.mobileNumber}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            
            
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>Shipping Address</th>
                    </tr>
                </thead>
                <tbody>
    
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Flat No.</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                name="flatNo"
                                value={deliveryAddress.flatNo}
                                onChange={handleAddressChange}
                                variant="outlined"
                                fullWidth
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Area</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                name="area"
                                value={deliveryAddress.area}
                                onChange={handleAddressChange}
                                variant="outlined"
                                fullWidth
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>City</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                name="city"
                                value={deliveryAddress.city}
                                onChange={handleAddressChange}
                                variant="outlined"
                                fullWidth
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>State</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                name="state"
                                value={deliveryAddress.state}
                                onChange={handleAddressChange}
                                variant="outlined"
                                fullWidth
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Pin Code</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                name="pinCode"
                                value={deliveryAddress.pincode}
                                onChange={handleAddressChange}
                                variant="outlined"
                                fullWidth
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Phone Number</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                name="mobileNumber"
                                value={deliveryAddress.mobileNumber}
                                onChange={handleAddressChange}
                                variant="outlined"
                                fullWidth
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
       
        </div>
    );
};

export default EditOrderCustomerAndShipping;
