import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiPlus } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import CommonClasses from "Styles/Common.module.css";
import ProductClasses from '../../../Product/Products.module.css';
import SkeletonLoad from "Components/TableLoad/load";
import { Services } from 'Services';
import { Modal, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Success, Error_Dark } from 'Util/Toast';
import Layout from 'Components/Layout';
import moment from 'moment';

const Banners = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [loading, setLoading] = useState(true);
    const [banners, setBanners] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [newBanner, setNewBanner] = useState('');
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [deleteBannerId, setDeleteBannerId] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [homeDeals, setHomeDeals] = useState([]);

    useEffect(() => {
        fetchBanners();
        fetchHomeDeals();
    }, []);

    const fetchBanners = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getBanners(token);
            if (response.Status === 1) {
                setBanners(response.banners);
            } else {
                console.error('Error fetching banners:', response.Message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching banners:', error);
            setLoading(false);
        }
    };

    const fetchHomeDeals = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getDeals(token);
            if (response.Status === 1) {
                setHomeDeals(response.homeDeals);
            } else {
                console.error('Error fetching homeDeals:', response.Message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching homeDeals:', error);
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setSelectedBanner(null);
        setNewBanner('');
        setOpenModal(false);
    };

    const handleDeleteBanner = (bannerId) => {
        setDeleteBannerId(bannerId);
        setDeleteConfirmation(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.deleteBanner(deleteBannerId, token);
            if (response.Status === 1) {
                fetchBanners();
                Success(response.Message);
                setDeleteConfirmation(false);
            } else {
                console.error('Error deleting banner:', response.Message);
            }
        } catch (error) {
            console.error('Error deleting banner:', error);
        }
    };

    const handleCancelDelete = () => {
        setDeleteConfirmation(false);
        setDeleteBannerId(null);
    };

    const handleMenuOpen = (event, bannerId) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setSelectedRowId(bannerId);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setSelectedRowId(null);
    };

    const handleEdit = () => {
        const banner = banners.find(b => b.bannerId === selectedRowId);
        handleMenuClose();
        navigate(`/editBanner/${selectedRowId}`, { state: { banner } }); // Pass banner object as state
    };

    const handleDelete = () => {
        handleDeleteBanner(selectedRowId);
        handleMenuClose();
    };

    const getDealName = (productDealId) => {
        const deal = homeDeals.find(deal => deal.productDealId === productDealId);
        return deal ? deal.dealName : 'NA';
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD MMM YYYY');
    };

    const columns = [
        { field: 'bannerId', headerName: 'Banner Id', width: 200 },
        {
            field: 'banner',
            headerName: 'Image',
            width: 200,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt={params.row.adminId}
                    style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                />
            ),
        },
        { field: 'createdAt', headerName: 'Date Ordered', width: 200, valueFormatter: (params) => formatDate(params) },
        {
            field: 'productDealId',
            headerName: 'Product Deal',
            width: 200,
            renderCell: (params) => (
                <span>{getDealName(params.value)}</span>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <div>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuOpen(event, params.row.bannerId)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                        sx={{ boxShadow: 'none' }}
                    >
                        <MenuItem onClick={handleEdit}>Edit</MenuItem>
                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    return (
        <Layout Active={"Banners"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Banners</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <h3 className={ProductClasses['main-header']}>Banners<span className={ProductClasses['count-of-users']}>({banners.length})</span></h3>
                <div className={CommonClasses['control-btns']}>
                    <Button style={{ marginBottom: '20px' }}
                        className={CommonClasses['button-for-add']}
                        onClick={() => navigate('/createbanner')} // Navigate to createbanner page on button click
                    >
                        <BiPlus size={20} /> Add Banner
                    </Button>
                </div>
                <div>
                    {loading ? (
                        <SkeletonLoad
                            TableHead={["Banner", "Actions"]}
                        />
                    ) : banners.length > 0 ? (
                        <div className={CommonClasses["Table"]} style={{ maxHeight: 600 }}>
                            <DataGrid
                                rows={banners}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                                pagination
                                getRowId={(row) => row.bannerId}
                                checkboxSelection
                                onSelectionModelChange={(newSelection) => {
                                    setSelectedRows(newSelection);
                                }}
                            />
                            {selectedRows.length > 0 && (
                                <div>
                                    <p>Selected Banners: {selectedRows.join(', ')}</p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <p>No Banners Found</p>
                        </div>
                    )}
                </div>
            </div>

            <Dialog
                open={deleteConfirmation}
                onClose={handleCancelDelete}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this banner?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default Banners;
