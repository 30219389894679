import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, Typography, IconButton, TextField, Radio, FormHelperText } from '@mui/material';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { BiPlus, BiSearch } from 'react-icons/bi';
import CloseIcon from '@mui/icons-material/Close';
import styles from './AddOrder.module.css';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import CommonClasses from '../../../Styles/Common.module.css';
import { Services } from 'Services';
import AddShippingAddressDialog from './AddShippingAddressDialog';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const AddOrderCustomerAndShipping = ({ userData, setUserData, errors, validate }) => {
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
    const [openShippingDialog, setOpenShippingDialog] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUserDetails, setSelectedUserDetails] = useState(userData.selectedUserDetails || null);
    const [shippingAddressDetails, setShippingAddressDetails] = useState(userData.deliveryAddress || null);
    const [activeTab, setActiveTab] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        if (openCustomerDialog) {
            const fetchCustomers = async () => {
                try {
                    const token = { Authorization: `token ${localStorage.getItem('token')}` };
                    const response = await Services.getUsers(4, token);
                    if (response.Status === 1) {
                        setUsers(Array.isArray(response.users) ? response.users : []);
                    } else {
                        console.error('Failed to fetch customers:', response.Message);
                        setUsers([]);
                    }
                } catch (error) {
                    console.error('Error fetching customers:', error);
                    setUsers([]);
                }
            };
            fetchCustomers();
        }
    }, [openCustomerDialog]);

    const handleCustomerDialogOpen = () => {
        setOpenCustomerDialog(true);
    };

    const handleCustomerDialogClose = () => {
        setOpenCustomerDialog(false);
    };

    const handleShippingDialogOpen = () => {
        setOpenShippingDialog(true);
    };

    const handleShippingDialogClose = () => {
        setOpenShippingDialog(false);
    };

    const handleCustomerChange = (id) => {
        setSelectedCustomer(id);
    };

    const handleAddCustomer = async () => {
        if (selectedCustomer) {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.getUserDetails(selectedCustomer, token);
                if (response.Status === 1) {
                    setSelectedUserDetails(response.user);
                    setUserData(prevUserData => ({
                        ...prevUserData,
                        selectedUserDetails: response.user
                    }));
                } else {
                    console.error('Failed to fetch user details:', response.Message);
                    setSelectedUserDetails(null);
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
                setSelectedUserDetails(null);
            }
        }
        setOpenCustomerDialog(false);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSaveShippingAddress = (address) => {
        setShippingAddressDetails(address);
        setUserData(prevUserData => ({
            ...prevUserData,
            deliveryAddress: address
        }));
        handleShippingDialogClose();
    };

    const formatDate = (dateString) => {
        return moment(dateString).format("DD-MM-YYYY");
    };

    const columns = [
        {
            field: 'radio',
            headerName: '',
            width: 50,
            renderCell: (params: GridRenderCellParams) => (
                <Radio
                    checked={selectedCustomer === params.id}
                    onChange={() => handleCustomerChange(params.id)}
                    value={params.id}
                    name="customer-radio-button"
                    inputProps={{ 'aria-label': `${params.id}` }}
                />
            ),
        },
        { field: 'firstName', headerName: 'Name', width: 150 },
        { field: 'company', headerName: 'Company', width: 200 },
        { field: 'createdAt', headerName: 'Date Joined', width: 200, valueFormatter: (params) => formatDate(params) },
        {
            field: 'isDocumentsVerified',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => (
                <div
                    style={{
                        backgroundColor: params.value === 1 ? '#79f4a3' : '#fef987',
                        color: 'Black',
                        width: '100px',
                        fontWeight: 'bold',
                        fontSize: '0.875rem',
                        borderRadius: '4px',
                        textAlign: 'center',
                        marginTop: '10px',
                        lineHeight: 'normal',
                        padding: '10px'
                    }}
                >
                    {params.value === 1 ? 'Verified' : 'Not Verified'}
                </div>
            ),
        },
    ];

    const filteredCustomers = users.filter(user =>
        (user.company && user.company.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (user.firstName && user.firstName.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const handleAddNewCustomer = () => {
        navigate('/adduser');
    };

    return (
        <div className={AddUserClasses['main-select-order-cust']}>
            <div className={AddUserClasses['main-user-address']}>
                <div className={AddUserClasses['main-userleft-address']}>
                    <h3 className={AddUserClasses['main-address-header']}>Customer Details</h3>
                    {errors.selectedUserDetails && (
                        <FormHelperText error>{errors.selectedUserDetails}</FormHelperText>
                    )}
                </div>
                <div className={AddUserClasses['main-userright-address']}>
                    <div className={CommonClasses['control-btns']}>
                        <Button
                            style={{ backgroundColor: 'white' }}
                            className={CommonClasses['button-for-add']}
                            onClick={handleCustomerDialogOpen}
                        >
                            <BiPlus size={20} /> Add Customer
                        </Button>
                    </div>
                </div>

                <Dialog open={openCustomerDialog} onClose={handleCustomerDialogClose} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Select Customer</Typography>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    placeholder="User name / Company name"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    InputProps={{
                                        startAdornment: <BiSearch />,
                                    }}
                                    style={{ marginRight: 8 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginRight: 8, color: 'white', backgroundColor: '#23232a' }}
                                    onClick={handleAddCustomer}
                                >
                                    Add
                                </Button>
                                <IconButton onClick={handleCustomerDialogClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <Typography variant="body1">Not an existing customer?</Typography>
                            <Button style={{ border: '1px solid gray', color: 'black' }} variant="outlined" color="primary" onClick={handleAddNewCustomer}>Add a new customer</Button>
                        </Box>
                        <div className={styles['table-container']}>
                            <DataGrid
                                rows={filteredCustomers}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => row.userId}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
            <div className={AddUserClasses['main-user-address']}>
                <div className={AddUserClasses['main-userleft-address']}>
                    <h3 className={AddUserClasses['main-address-header']}>Shipping Address</h3>
                    {errors.deliveryAddress && (
                        <FormHelperText error>{errors.deliveryAddress}</FormHelperText>
                    )}
                </div>
                <div className={AddUserClasses['main-userright-address']}>
                    <div className={CommonClasses['control-btns']}>
                        <Button
                            style={{ backgroundColor: 'white' }}
                            className={CommonClasses['button-for-add']}
                            onClick={handleShippingDialogOpen}
                        >
                            <BiPlus size={20} /> Add Shipping Address
                        </Button>
                    </div>
                </div>
            </div>
            <div className={AddUserClasses['selected-user-details']}>
                {selectedUserDetails && (
                    <div>
                        <table className={AddUserClasses['user-table']}>
                            <thead>
                                <tr>
                                    <th colSpan="2" className={AddUserClasses['table-header']}>About</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>UserId</td>
                                    <td className={AddUserClasses['table-value']}>{selectedUserDetails.userId}</td>
                                </tr>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>Company</td>
                                    <td className={AddUserClasses['table-value']}>{selectedUserDetails.company}</td>
                                </tr>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>Mobile Number</td>
                                    <td className={AddUserClasses['table-value']}>{selectedUserDetails.mobileNumber}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            <div className={AddUserClasses['selected-user-details']}>
                {shippingAddressDetails && (
                    <div>
                        <table className={AddUserClasses['user-table']}>
                            <thead>
                                <tr>
                                    <th colSpan="2" className={AddUserClasses['table-header']}>Shipping Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>First Name:</td>
                                    <td className={AddUserClasses['table-value']}>{shippingAddressDetails.firstName}</td>
                                </tr>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>Last Name:</td>
                                    <td className={AddUserClasses['table-value']}>{shippingAddressDetails.lastName}</td>
                                </tr>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>Company:</td>
                                    <td className={AddUserClasses['table-value']}>{shippingAddressDetails.company}</td>
                                </tr>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>Mobile Number:</td>
                                    <td className={AddUserClasses['table-value']}>{shippingAddressDetails.mobileNumber}</td>
                                </tr>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>Delivery Address:</td>
                                    <td className={AddUserClasses['table-value']}>{shippingAddressDetails.deliveryAddress}</td>
                                </tr>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>City:</td>
                                    <td className={AddUserClasses['table-value']}>{shippingAddressDetails.city}</td>
                                </tr>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>State:</td>
                                    <td className={AddUserClasses['table-value']}>{shippingAddressDetails.state}</td>
                                </tr>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>Area:</td>
                                    <td className={AddUserClasses['table-value']}>{shippingAddressDetails.area}</td>
                                </tr>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>Pin Code:</td>
                                    <td className={AddUserClasses['table-value']}>{shippingAddressDetails.pinCode}</td>
                                </tr>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>Flat No:</td>
                                    <td className={AddUserClasses['table-value']}>{shippingAddressDetails.flatNo}</td>
                                </tr>
                                <tr>
                                    <td className={AddUserClasses['table-label']}>Address Type:</td>
                                    <td className={AddUserClasses['table-value']}>{shippingAddressDetails.addressType === 1 ? 'Home' : 'Office'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            <AddShippingAddressDialog
                open={openShippingDialog}
                handleClose={handleShippingDialogClose}
                handleSave={handleSaveShippingAddress}
                shippingAddress={shippingAddressDetails || {}}
                errors={errors}
                validate={validate}
            />
        </div>
    );
};

export default AddOrderCustomerAndShipping;
