import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiPlus } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import CommonClasses from "Styles/Common.module.css";
import ProductClasses from '../Product/Products.module.css';
import SkeletonLoad from "Components/TableLoad/load";
import { Services } from 'Services';
import { Modal, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Success, Error_Dark } from 'Util/Toast';
import Layout from 'Components/Layout';

const ProductCategory = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [productCategories, setProductCategories] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [newCategory, setNewCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [deleteCategoryId, setDeleteCategoryId] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);

    useEffect(() => {
        fetchProductCategories();
    }, []);

    const fetchProductCategories = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getCategories(token);
            if (response.Status === 1) {
                setProductCategories(response.productCategories);
            } else {
                console.error('Error fetching productCategories:', response.Message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching productCategories:', error);
            setLoading(false);
        }
    };

    const handleOpenModal = (category) => {
        setSelectedCategory(category || null);
        setNewCategory(category ? category.categoryName : '');
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedCategory(null);
        setNewCategory('');
        setOpenModal(false);
    };

    const handleAddCategory = async () => {
        let body = { categoryName: newCategory };
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.addCategory(JSON.stringify(body), token);
            if (response.Status === 1) {
                fetchProductCategories();
                handleCloseModal();
                Success(response.Message);
            } else {
                console.error('Error adding category:', response.Message);
            }
        } catch (error) {
            console.error('Error adding category:', error);
        }
    };

    const handleEditCategory = async () => {
        let body = { categoryName: newCategory };
        try {
            if (!selectedCategory) {
                console.error('No category selected for editing.');
                return;
            }

            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.editCategory(selectedCategory.productCategoryId, JSON.stringify(body), token);
            if (response.Status === 1) {
                fetchProductCategories();
                handleCloseModal();
                Success(response.Message);
            } else {
                console.error('Error updating category:', response.Message);
            }
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };

    const handleDeleteCategory = (productCategoryId) => {
        setDeleteCategoryId(productCategoryId);
        setDeleteConfirmation(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.deleteCategory(deleteCategoryId, token);
            if (response.Status === 1) {
                fetchProductCategories();
                Success(response.Message);
                setDeleteConfirmation(false);
            } else {
                console.error('Error deleting category:', response.Message);
            }
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    const handleCancelDelete = () => {
        setDeleteConfirmation(false);
        setDeleteCategoryId(null);
    };

    const handleMenuOpen = (event, productCategoryId) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setSelectedRowId(productCategoryId);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setSelectedRowId(null);
    };

    const handleEdit = () => {
        const category = productCategories.find(c => c.productCategoryId === selectedRowId);
        handleOpenModal(category);
        handleMenuClose();
    };

    const handleDelete = () => {
        handleDeleteCategory(selectedRowId);
        handleMenuClose();
    };

    const columns = [
        { field: 'categoryName', headerName: 'Product Category', width: 700 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 600,
            renderCell: (params) => (
                <div>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuOpen(event, params.row.productCategoryId)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                        sx={{ boxShadow: 'none' }}
                    >
                        <MenuItem onClick={handleEdit}>Edit</MenuItem>
                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    return (
        <Layout Active={"Product Category"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Product Categories</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <h3 className={ProductClasses['main-header']}>Product Categories<span className={ProductClasses['count-of-users']}>({productCategories.length})</span></h3>
                <div className={CommonClasses['control-btns']}>
                    <Button style={{marginBottom: '20px'}}
                        className={CommonClasses['button-for-add']}
                        onClick={() => handleOpenModal(null)}
                    >
                        <BiPlus size={20} /> Add Product Category
                    </Button>
                </div>
                <div>
                    {loading ? (
                        <SkeletonLoad
                            TableHead={["Product Category", "Actions"]}
                        />
                    ) : productCategories.length > 0 ? (
                        <div className={CommonClasses["Table"]} style={{ maxHeight: 600 }}>
                            <DataGrid
                                rows={productCategories}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                                pagination
                                getRowId={(row) => row.productCategoryId}
                                checkboxSelection
                                onSelectionModelChange={(newSelection) => {
                                    setSelectedRows(newSelection);
                                }}
                            />
                            {selectedRows.length > 0 && (
                                <div>
                                    <p>Selected Product Categories: {selectedRows.join(', ')}</p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <p>No Product Categories Found</p>
                        </div>
                    )}
                </div>
            </div>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className={ProductClasses.modal}>
                    <h2 id="modal-title">{selectedCategory ? 'Edit Product Category' : 'Add Product Category'}</h2>
                    <TextField
                        label="Product Category"
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <button className={ProductClasses['edit-btn']} onClick={selectedCategory ? handleEditCategory : handleAddCategory}>{selectedCategory ? 'Edit' : 'Add'}</button>
                    <button className={ProductClasses['del-btn']} onClick={handleCloseModal}>Cancel</button>
                </div>
            </Modal>

            <Dialog
                open={deleteConfirmation}
                onClose={handleCancelDelete}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this product category?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete}
                    color="secondary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default ProductCategory;
