import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import SkeletonLoad from 'Components/TableLoad/load';
import { Services } from 'Services';
import Layout from 'Components/Layout';
import moment from 'moment';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../../Product/Products.module.css';
import styles from '../../orders/addOrder/AddOrder.module.css';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import { Breadcrumbs, Button, Link, Stack, Typography, Chip } from '@mui/material';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ViewDealEndingSoonProducts = () => {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const { dealsEndingSoonId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDealsEndingSoonProducts = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getDealEndingSoonProducts(dealsEndingSoonId, token);
                if (response.Status === 1) {
                    setProducts(response.products);
                } else {
                    console.error('Error fetching data:', response.Message);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDealsEndingSoonProducts();
    }, [dealsEndingSoonId]);

    const getCategoryName = (categoryId) => {
        switch (categoryId) {
            case 1:
                return 'Medical';
            case 2:
                return 'Surgical';
            case 3:
                return 'Ayurveda';
            case 4:
                return 'Skin care';
            default:
                return 'Unknown';
        }
    };

    const columns = [
        { field: 'dealsEndingSoonId', headerName: 'ID', width: 100 },
        {
            field: 'productImage',
            headerName: 'Image',
            width: 200,
            renderCell: (params) => {
                const imageUrl = params.value && params.value.length > 0 ? params.value[0] : '';
                return (
                    <img src={imageUrl} alt="Product" style={{ width: '50px', height: '50px' }} />
                );
            },
        },
        { field: 'productName', headerName: 'Name', width: 200 },
        {
            field: 'productCategoryId',
            headerName: 'Category',
            width: 200,
            renderCell: (params) => (
                <Chip
                    label={getCategoryName(params.value)}
                    style={{
                        backgroundColor: getCategoryChipColor(params.value),
                        color: 'white',
                        fontWeight: 'bold',
                    }}
                />
            ),
        },
        { field: 'stockQty', headerName: 'Stock', width: 200 },
        { field: 'productDiscountPrice', headerName: 'Selling Price', width: 200 },
        { field: 'manufacturer', headerName: 'Manufacturer', width: 200 },
        {
            field: 'endDate',
            headerName: 'Deal End Date',
            width: 250,
            renderCell: (params) => (
                moment(params.value).format('MMMM Do YYYY, h:mm:ss a')
            ),
        },
    ];

    const getCategoryChipColor = (categoryId) => {
        switch (categoryId) {
            case 1:
                return '#60E19B'; // Medical
            case 2:
                return '#BBDED6'; // Surgical
            case 3:
                return '#FFB6B9'; // Ayurveda
            case 4:
                return '#FAE3D9'; // Skin care
            default:
                return '#757575'; // Default color
        }
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/dealsendingsoon')}>
            Deals Ending Soon
        </Link>,
        <Typography key="3" color="text.primary">
            View Deal Ending Soon Products
        </Typography>,
    ];

    return (
        <Layout Active={"Deals Ending Soon"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Deals Ending Soon Details</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div className={AddUserClasses['new-main-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>
                <h3 className={ProductClasses['main-header']}>
                    Deals Ending Soon Products
                    <span className={ProductClasses['count-of-users']}>({products.length})</span>
                </h3>
                <div>
                    {loading ? (
                        <SkeletonLoad TableHead={["ID", "Name", "Category", "Stock", "Selling Price", "Manufacturer", "Deal End Date", "Image"]} />
                    ) : (
                        <div className={CommonClasses['Table']} style={{ maxHeight: 600 }}>
                            <DataGrid
                                rows={products}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => row.dealsEndingSoonId}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default ViewDealEndingSoonProducts;
