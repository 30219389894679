import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiPlus } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import CommonClasses from "Styles/Common.module.css";
import ProductClasses from '../../Product/Products.module.css';
import SkeletonLoad from "Components/TableLoad/load";
import { Services } from 'Services';
import { Modal, TextField, Button, IconButton, Menu, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Layout from 'Components/Layout';
import moment from 'moment';

const DealsEndingSoon = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [dealsEndingSoon, setDealsEndingSoon] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [newBrand, setNewBrand] = useState('');
    const [selectedBrand, setSelectedBrand] = useState(null); // Declare selectedBrand state
    const [selectedRows, setSelectedRows] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);

    useEffect(() => {
        fetchDealsEndingSoon();
    }, []);

    const fetchDealsEndingSoon = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getDealEndingSoon(token);
            if (response.Status === 1) {
                const formattedDeals = response.dealsEndingSoon.map(deal => ({
                    ...deal,
                    statusLabel: deal.status === 1 ? 'Active' : 'Expired'
                }));
                setDealsEndingSoon(formattedDeals);
            } else {
                console.error('Error fetching deals ending soon:', response.Message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching deals ending soon:', error);
            setLoading(false);
        }
    };

    const columns = [
        { field: 'dealsEndingSoonId', headerName: 'ID', width: 100 },
        { field: 'dealStartDate', headerName: 'Deal Start Date', width: 250, renderCell: (params) => formatDate(params.value) },
        { field: 'dealsEndDate', headerName: 'Deal End Date', width: 250, renderCell: (params) => formatDate(params.value) },
        { field: 'statusLabel', headerName: 'Status', width: 200 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => {
                            setMenuAnchorEl(event.currentTarget);
                            setSelectedRowId(params.id);
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={() => setMenuAnchorEl(null)}
                    >
                        <MenuItem onClick={() => handleEdit(params.id)}>Edit</MenuItem>
                        <MenuItem onClick={() => handleDelete(params.id)}>Delete</MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    const handleEdit = (id) => {
        // Edit logic here
        setMenuAnchorEl(null);
    };

    const handleDelete = (id) => {
        // Delete logic here
        setMenuAnchorEl(null);
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD MMM YYYY');
    };

    const handleCreateDeal = () => {
        navigate('/createdealsendingsoon');
    };

    const handleRowClick = (params) => {
        navigate(`/viewdealendingsoonproducts/${params.id}`);
    };

    return (
        <Layout Active={"Deals Ending Soon"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Deals Ending Soon</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <h3 className={ProductClasses['main-header']}>Deals Ending Soon<span className={ProductClasses['count-of-users']}>({dealsEndingSoon.length})</span></h3>
                <div className={CommonClasses['control-btns']}>
                    <Button style={{marginBottom: '20px'}} className={CommonClasses['button-for-add']} onClick={handleCreateDeal}>
                        <BiPlus size={20} /> Create Deals Ending Soon
                    </Button>
                </div>
                <div>
                    {loading ? (
                        <SkeletonLoad TableHead={["ID", "Deal Start Date", "Deal End Date", "Status", "Actions"]} />
                    ) : dealsEndingSoon.length > 0 ? (
                        <div className={CommonClasses["Table"]} style={{ maxHeight: 600 }}>
                            <DataGrid
                                rows={dealsEndingSoon}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                                pagination
                                getRowId={(row) => row.dealsEndingSoonId}
                                checkboxSelection
                                onRowClick={handleRowClick}
                                onSelectionModelChange={(newSelection) => {
                                    setSelectedRows(newSelection);
                                }}
                            />
                            {selectedRows.length > 0 && (
                                <div>
                                    <p>Selected Deals Ending Soon: {selectedRows.join(', ')}</p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <p>No Deals Ending Soon Found</p>
                        </div>
                    )}
                </div>
            </div>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className={ProductClasses.modal} data-aos="fade-left"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500">
                    <h2 id="modal-title">{selectedBrand ? 'Edit Deal Ending Soon' : 'Add Deal Ending Soon'}</h2>
                    <TextField
                        label="Deal Ending Soon"
                        value={newBrand}
                        onChange={(e) => setNewBrand(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <button className={ProductClasses['edit-btn']}>Add</button>
                    <button className={ProductClasses['del-btn']} onClick={() => setOpenModal(false)}>Cancel</button>
                </div>
            </Modal>
        </Layout>
    );
};

export default DealsEndingSoon;
