import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Button, Breadcrumbs, Link, Typography, Stack, Tabs, Tab, Box, Paper, IconButton, Popover,
    Dialog, DialogTitle, DialogContent, DialogActions,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Settings } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from 'Components/Layout';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import CommonClasses from '../../../Styles/Common.module.css';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import EditUserOverView from './EditUserOverView';
import EditUserAddress from './EditUserAddress';
import EditUserDocuments from './EditUserDocuments';
import { Services } from 'Services';

// Import your external CSS file
import EditUserClasses from './EditUser.module.css';
import { Success } from 'Util/Toast';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const EditUser = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null); // State for Popover anchor element
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false); // State for Reset Password modal
    const [currentPassword, setCurrentPassword] = useState(''); // State for Current Password
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { userId } = useParams();

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/users')}>
            Users
        </Link>,
        <Typography key="3" color="text.primary">
            Edit User
        </Typography>,
    ];

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getUserDetails(userId, token);
                setUser(response.user); // Adjust this line based on your API response structure
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [userId]);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    const handleResetPassword = () => {
        setShowResetPasswordModal(true); // Open Reset Password modal
        setAnchorEl(null); // Close Popover
    };

    const handleCurrentPasswordChange = (event) => {
        setCurrentPassword(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleSaveNewPassword = async () => {
        if (newPassword !== confirmPassword) {
            alert("New password and confirm password do not match.");
            return;
        }

        const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
        const passwordData = {
            newPassword: newPassword,
            currentPassword: currentPassword,
            userId: userId,
        };

        try {
            const response = await Services.resetUserPassword(passwordData, token);
            if (response.Status === 1) {
                // Handle success
                setShowResetPasswordModal(false);
                Success("Password Updated Successfully");

                // Clear the password fields
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } else {
                // Handle error
                alert(response.Message);
            }
        } catch (error) {
            // Handle network or other errors
            console.error('An error occurred:', error);
        }

        setShowResetPasswordModal(false); // Close Reset Password modal
    };

    return (
        <Layout Active={'Users'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit User</title>
            </Helmet>
            <div className={AddUserClasses['main-of-tables']}>
                <div className={AddUserClasses['new-main-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}>
                                <MdOutlineArrowBackIos />Back
                            </Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>
                {user ? (
                    <div className={ViewUserClasses['main-name']}>
                        <div className={ViewUserClasses['inside-leftname']}>
                            <h1 className={ViewUserClasses['user-fullname']}>{user.firstName} {user.lastName}</h1>
                            <p style={{ backgroundColor: user.isDocumentsVerified === 1 ? '#60E19B' : '#FFE600', color: 'white' }} className={ViewUserClasses['user-para-stat']}>{user.isDocumentsVerified === 1 ? 'Verified' : user.isDocumentsVerified === 0 ? 'Not Verified' : 'NA'}</p>
                        </div>
                        <div className={ViewUserClasses['inside-rightname']}>
                            <IconButton onClick={handlePopoverOpen}>
                                <Settings className={ViewUserClasses['rotating-settings-icon']} />
                            </IconButton>
                            <Popover
                                open={openPopover}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <Paper>
                                    <List>
                                        <ListItem button onClick={handleResetPassword}>
                                            <ListItemText primary="Reset Password" />
                                        </ListItem>
                                        {/* Add other options as needed */}
                                    </List>
                                </Paper>
                            </Popover>
                        </div>
                    </div>
                ) : ''}
            </div>
            <div className={AddUserClasses['main-prod-second-container']}>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="edit product tabs">
                    <Tab label="Overview" />
                    <Tab label="Address" />
                    <Tab label="Documents" />
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                    <EditUserOverView />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <EditUserAddress />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <EditUserDocuments />
                </TabPanel>
            </div>

            {/* Reset Password Modal */}
            <Dialog
                open={showResetPasswordModal}
                onClose={() => setShowResetPasswordModal(false)}
                className={EditUserClasses["reset-password-dialog"]}
            >
                <DialogTitle className={EditUserClasses["reset-password-dialog-title"]}>Reset Password</DialogTitle>
                <DialogContent className={EditUserClasses["reset-password-dialog-content"]}>
                    <div className={EditUserClasses["input-container"]}>
                        <label htmlFor="current-password" className={EditUserClasses["input-label"]}>Current Password</label>
                        <input
                            id="current-password"
                            type="password"
                            fullWidth
                            value={currentPassword}
                            onChange={handleCurrentPasswordChange}
                            className={EditUserClasses["input-field"]}
                        />
                    </div>
                    <div className={EditUserClasses["input-container"]}>
                        <label htmlFor="new-password" className={EditUserClasses["input-label"]}>New Password</label>
                        <input
                            id="new-password"
                            type="password"
                            fullWidth
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            className={EditUserClasses["input-field"]}
                        />
                    </div>
                    <div className={EditUserClasses["input-container"]}>
                        <label htmlFor="confirm-password" className={EditUserClasses["input-label"]}>Confirm New Password</label>
                        <input
                            id="confirm-password"
                            type="password"
                            fullWidth
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            className={EditUserClasses["input-field"]}
                        />
                    </div>
                </DialogContent>
                <div className={EditUserClasses["reset-password-dialog-actions"]}>
                    <button className={EditUserClasses['btn-pass-cancel']} onClick={() => setShowResetPasswordModal(false)}>Cancel</button>
                    <button className={EditUserClasses['btn-pass-save']} onClick={handleSaveNewPassword}>Reset</button>
                </div>
            </Dialog>
        </Layout>
    );
};

export default EditUser;
