import React, { useEffect, useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import { Services } from 'Services';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const AddProductOverView = ({ userData, setUserData, errors, validate }) => {
    const [loading, setLoading] = useState(true);
    const [brands, setBrands] = useState([]);
    const [types, setTypes] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [homeDeals, setHomeDeals] = useState([]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        let updatedValue = value;

        if (name === "productDealIds") {
            updatedValue = value;
        } else if (name === "discount" || name === "productPrice") {
            updatedValue = parseInt(value, 10);

            // Cap discount at 95%
            if (name === "discount") {
                updatedValue = Math.min(updatedValue, 95);
            }

            // Calculate productDiscountPrice if both productPrice and discount are present
            if (name === "productPrice" && userData.discount !== undefined) {
                const productPrice = parseInt(value, 10);
                const discount = Math.min(parseInt(userData.discount, 10), 95); // Cap discount here too
                const productDiscountPrice = productPrice - (productPrice * discount / 100);
                setUserData(prevUserData => ({
                    ...prevUserData,
                    productDiscountPrice: productDiscountPrice.toFixed(2) // Ensure it's formatted as needed
                }));
            } else if (name === "discount" && userData.productPrice !== undefined) {
                const productPrice = parseInt(userData.productPrice, 10);
                const discount = Math.min(parseInt(value, 10), 95); // Cap discount here too
                const productDiscountPrice = productPrice - (productPrice * discount / 100);
                setUserData(prevUserData => ({
                    ...prevUserData,
                    productDiscountPrice: productDiscountPrice.toFixed(2) // Ensure it's formatted as needed
                }));
            }
        } else {
            if (name === "brandName" || name === "typeName") {
                const selectedOption = brands.find(brand => brand.brandName === value) ||
                    types.find(type => type.typeName === value);
                updatedValue = selectedOption ? selectedOption.brandName || selectedOption.typeName : "";
            } else if (name === "isActive") {
                updatedValue = value === "Active" ? 1 : 0;
            }
        }

        setUserData(prevUserData => ({
            ...prevUserData,
            [name]: updatedValue
        }));

        validate(name, updatedValue);
    };



    const handleDateChange = (name, value) => {
        setUserData(prevUserData => ({
            ...prevUserData,
            [name]: value ? dayjs(value).format('YYYY-MM-DD') : ''
        }));

        // Remove error when user starts typing
        validate(name, value ? dayjs(value).format('YYYY-MM-DD') : '');
    };


    const handleEstimatedDeliveryChange = (event) => {
        const { name, value } = event.target;
        setUserData(prevUserData => ({
            ...prevUserData,
            [name]: value
        }));

        validate(name, value);
    };

    const handleDaysChange = (event) => {
        const { value } = event.target;
        setUserData(prevUserData => ({
            ...prevUserData,
            estimatedDeliveryDays: value
        }));

        validate('estimatedDeliveryDays', value);
    };

    useEffect(() => {
        fetchBrands();
    }, []);

    const fetchBrands = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getBrands(token);
            if (response.Status === 1) {
                setBrands(response.brands);
            } else {
                console.error('Error fetching brands:', response.Message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching brands:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTypes();
    }, []);

    const fetchTypes = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getTypes(token);
            if (response.Status === 1) {
                setTypes(response.types);
            } else {
                console.error('Error fetching types:', response.Message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching types:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProductCategories();
    }, []);

    const fetchProductCategories = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getCategories(token);
            if (response.Status === 1) {
                setProductCategories(response.productCategories);
            } else {
                console.error('Error fetching productCategories:', response.Message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching productCategories:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHomeDeals();
    }, []);

    const fetchHomeDeals = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getDeals(token);
            if (response.Status === 1) {
                setHomeDeals(response.homeDeals);
            } else {
                console.error('Error fetching homeDeals:', response.Message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching homeDeals:', error);
            setLoading(false);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
                <table className={AddUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={AddUserClasses['table-header']}>Basic Product Information</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <TextField
                                            style={{ width: '400px' }}
                                            required
                                            id="productName"
                                            name="productName"
                                            value={userData.productName || ''}
                                            onChange={handleChange}
                                            // label="Product name"
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                            error={!!errors.productName}
                                            helperText={errors.productName}
                                            fullWidth
                                            placeholder='Product name *'
                                        />
                                    </td>
                                </tr>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel id="brand-label">Brand *</InputLabel>
                                            <Select
                                                style={{ width: '400px' }}
                                                labelId="brand-label"
                                                id="brandName"
                                                name="brandName"
                                                value={userData.brandName || ''}
                                                onChange={handleChange}
                                                label="Brand"
                                                fullWidth
                                                variant="outlined"
                                                error={!!errors.brandName}
                                                helperText={errors.brandName}
                                            >
                                                {brands.map(brand => (
                                                    <MenuItem key={brand.brandId} value={brand.brandName}>
                                                        {brand.brandName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.brandName && <p className={AddUserClasses['error-text']}>{errors.brandName}</p>}
                                        </FormControl>
                                    </td>
                                </tr>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel id="brand-label">Type *</InputLabel>
                                            <Select
                                                style={{ width: '400px' }}
                                                labelId="type-label"
                                                id="typeName"
                                                name="typeName"
                                                value={userData.typeName || ''}
                                                onChange={handleChange}
                                                label="Type"
                                                fullWidth
                                                variant="outlined"
                                                error={!!errors.typeName}
                                            >
                                                {types.map(type => (
                                                    <MenuItem key={type.typeId} value={type.typeName}>
                                                        {type.typeName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.typeName && <p className={AddUserClasses['error-text']}>{errors.typeName}</p>}
                                        </FormControl>
                                    </td>
                                </tr>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel id="brand-label">Category *</InputLabel>
                                            <Select
                                                style={{ width: '400px' }}
                                                labelId="category-label"
                                                id="productCategoryId"
                                                name="productCategoryId"
                                                value={userData.productCategoryId || ''}
                                                onChange={handleChange}
                                                label="Category"
                                                fullWidth
                                                variant="outlined"
                                                error={!!errors.productCategoryId}
                                            >
                                                {productCategories.map(category => (
                                                    <MenuItem key={category.productCategoryId} value={category.productCategoryId}>
                                                        {category.categoryName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.productCategoryId && <p className={AddUserClasses['error-text']}>{errors.productCategoryId}</p>}
                                        </FormControl>
                                    </td>
                                </tr>
                            </Grid>
                        </Grid>
                    </tbody>
                </table>
                <table className={AddUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={AddUserClasses['table-header']}>Manufacturer Information</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <TextField
                                            style={{ width: '400px' }}
                                            required
                                            id="manufacturer"
                                            name="manufacturer"
                                            value={userData.manufacturer || ''}
                                            onChange={handleChange}
                                            // label="Manufacturer"
                                            placeholder='Manufacturer *'
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                            error={!!errors.manufacturer}
                                            helperText={errors.manufacturer}
                                        />
                                    </td>
                                </tr>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <tr style={{ display: 'flex', flexDirection: 'column' }}>
                                    <td className={AddUserClasses['table-value']}>
                                        <DatePicker
                                            label="Manufacturing Date *"
                                            style={{ width: '400px' }}
                                            fullWidth
                                            value={userData.manufacturerDate ? dayjs(userData.manufacturerDate) : null}
                                            onChange={(date) => handleDateChange('manufacturerDate', date)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    variant="outlined"
                                                    margin="dense"
                                                    className={AddUserClasses['table-textfield-all']}
                                                    error={!!errors.manufacturerDate}
                                                    helperText={errors.manufacturerDate}
                                                />
                                            )}
                                        />
                                    </td>
                                    {errors.manufacturerDate && <p style={{ marginLeft: '20px' }} className={AddUserClasses['error-text']}>{errors.manufacturerDate}</p>}
                                </tr>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <tr style={{ display: 'flex', flexDirection: 'column' }}>
                                    <td className={AddUserClasses['table-value']}>
                                        <DatePicker
                                            label="Expiry Date *"
                                            style={{ width: '400px' }}
                                            fullWidth
                                            value={userData.expiryDate ? dayjs(userData.expiryDate) : null}
                                            onChange={(date) => handleDateChange('expiryDate', date)}
                                            disablePast
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    variant="outlined"
                                                    margin="dense"
                                                    className={AddUserClasses['table-textfield-all']}
                                                    error={!!errors.expiryDate}
                                                    helperText={errors.expiryDate}
                                                />
                                            )}
                                        />
                                    </td>
                                    {errors.expiryDate && <p style={{ marginLeft: '20px' }} className={AddUserClasses['error-text']}>{errors.expiryDate}</p>}
                                </tr>
                            </Grid>
                        </Grid>
                    </tbody>
                </table>
                <table className={AddUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={AddUserClasses['table-header']}>Pricing Information</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <TextField
                                            style={{ width: '400px' }}
                                            // label="MRP"
                                            placeholder='MRP *'
                                            fullWidth
                                            required
                                            id="productPrice"
                                            name="productPrice"
                                            type="number"
                                            value={userData.productPrice || ''}
                                            onChange={handleChange}
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                            error={!!errors.productPrice}
                                            helperText={errors.productPrice}
                                        />
                                    </td>
                                </tr>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <TextField
                                            style={{ width: '400px' }}
                                            required
                                            id="discount"
                                            name="discount"
                                            type="number"
                                            value={userData.discount || ''}
                                            onChange={handleChange}
                                            // label="Discount"
                                            placeholder='Discount'
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                            error={!!errors.discount}
                                            helperText={errors.discount}
                                        />
                                    </td>
                                </tr>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <TextField
                                            style={{ width: '400px' }}
                                            // label="Selling Price"
                                            placeholder='Selling Price'
                                            fullWidth
                                            required
                                            id="productDiscountPrice"
                                            name="productDiscountPrice"
                                            type="number"
                                            value={userData.productDiscountPrice || ''}
                                            onChange={handleChange}
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                            error={!!errors.productDiscountPrice}
                                            helperText={errors.productDiscountPrice}
                                        />
                                    </td>
                                </tr>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel id="deal-label">Deal *</InputLabel>
                                            <Select
                                                style={{ width: '400px' }}
                                                labelId="deal-label"
                                                id="productDealId"
                                                name="productDealId"
                                                multiple
                                                value={userData.productDealId || []}
                                                onChange={handleChange}
                                                label="Deal"
                                                fullWidth
                                                variant="outlined"
                                                error={!!errors.productDealId}
                                            >
                                                {homeDeals.map(deal => (
                                                    <MenuItem key={deal.productDealId} value={deal.productDealId}>
                                                        {deal.dealName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.productDealId && <p className={AddUserClasses['error-text']}>{errors.productDealId}</p>}
                                        </FormControl>
                                    </td>
                                </tr>
                            </Grid>
                        </Grid>
                    </tbody>
                </table>
                <table className={AddUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={AddUserClasses['table-header']}>Stock and Sales Information</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <TextField
                                            style={{ width: '400px' }}
                                            // label="Stock Availability"
                                            placeholder='Stock Availability *'
                                            fullWidth
                                            required
                                            id="stockQty"
                                            name="stockQty"
                                            value={userData.stockQty || ''}
                                            onChange={handleChange}
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                            error={!!errors.stockQty}
                                            helperText={errors.stockQty}
                                        />
                                    </td>
                                </tr>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <TextField
                                            style={{ width: '400px' }}
                                            // label="Units Sold"
                                            placeholder='Units Sold *'
                                            fullWidth
                                            required
                                            id="unitsSold"
                                            name="unitsSold"
                                            value={userData.unitsSold || ''}
                                            onChange={handleChange}
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                            error={!!errors.unitsSold}
                                            helperText={errors.unitsSold}
                                        />
                                    </td>
                                </tr>
                            </Grid>
                        </Grid>
                    </tbody>
                </table>
                <table className={AddUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={AddUserClasses['table-header']}>Identification and Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <TextField
                                            style={{ width: '400px' }}
                                            required
                                            id="sku"
                                            name="sku"
                                            value={userData.sku || ''}
                                            onChange={handleChange}
                                            // label="SKU"
                                            placeholder='SKU *'
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                            error={!!errors.sku}
                                            helperText={errors.sku}
                                        />
                                    </td>
                                </tr>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <tr style={{ display: 'flex', flexDirection: 'column' }}>
                                    <td className={AddUserClasses['table-value']}>
                                        <FormControl fullWidth variant="outlined" margin="dense">
                                            <InputLabel id="isActive-label">Is Active *</InputLabel>
                                            <Select
                                                style={{ width: '400px' }}
                                                labelId="isActive-label"
                                                id="isActive"
                                                name="isActive"
                                                value={userData.isActive === 1 ? "Active" : userData.isActive === 0 ? "Inactive" : ''}
                                                onChange={handleChange}
                                                label="Is Active"
                                                error={!!errors.isActive}
                                                helperText={errors.isActive}
                                            >
                                                <MenuItem value="Active">Active</MenuItem>
                                                <MenuItem value="Inactive">Inactive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </td>
                                    {errors.isActive && <p style={{ marginLeft: '20px' }} className={AddUserClasses['error-text']}>{errors.isActive}</p>}
                                </tr>
                            </Grid>
                        </Grid>
                    </tbody>
                </table>
                <table className={AddUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={AddUserClasses['table-header']}>Packaging & Delivery Information</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <TextField
                                            style={{ width: '400px' }}
                                            required
                                            id="packageSize"
                                            name="packageSize"
                                            value={userData.packageSize || ''}
                                            onChange={handleChange}
                                            // label="Package Size"
                                            placeholder='Package Size *'
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                            error={!!errors.packageSize}
                                            helperText={errors.packageSize}
                                        />
                                    </td>
                                </tr>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <FormControl style={{ width: '150px', position: 'absolute', marginLeft: '250px', zIndex: '1000' }} margin="dense" variant="outlined">
                                            <InputLabel id="days-label">Days *</InputLabel>
                                            <Select
                                                labelId="days-label"
                                                id="estimatedDeliveryDays"
                                                name="estimatedDeliveryDays"
                                                value={userData.estimatedDeliveryDays || ''}
                                                onChange={handleDaysChange}
                                                label="Days"
                                                error={!!errors.estimatedDeliveryDays}
                                                helperText={errors.estimatedDeliveryDays}
                                            >
                                                {[...Array(30).keys()].map(day => (
                                                    <MenuItem key={day} value={`${day + 1} days`}>{`${day + 1} days`}</MenuItem>
                                                ))}
                                            </Select>
                                            {errors.estimatedDeliveryDays && <p style={{ marginLeft: '20px', width: '200px' }} className={AddUserClasses['error-text']}>{errors.estimatedDeliveryDays}</p>}
                                        </FormControl>
                                        <TextField
                                            // label="Estimated Delivery"
                                            placeholder='Estimated Delivery *'
                                            style={{ width: '250px', marginRight: '16px', position: 'relative' }}
                                            name="estimatedDeliveryText"
                                            value={userData.estimatedDeliveryText || ''}
                                            onChange={handleEstimatedDeliveryChange}
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                            error={!!errors.estimatedDeliveryText}
                                            helperText={errors.estimatedDeliveryText}
                                        />
                                    </td>
                                </tr>
                            </Grid>
                        </Grid>
                    </tbody>
                </table>
                <table className={AddUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={AddUserClasses['table-header']}>Metrics</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <TextField
                                            style={{ width: '400px' }}
                                            // label="Metric Type"
                                            placeholder='Metric Type *'
                                            fullWidth
                                            required
                                            id="metricType"
                                            name="metricType"
                                            value={userData.metricType || ''}
                                            onChange={handleChange}
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                            error={!!errors.metricType}
                                            helperText={errors.metricType}
                                        />
                                    </td>
                                </tr>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <tr>
                                    <td className={AddUserClasses['table-value']}>
                                        <TextField
                                            style={{ width: '400px' }}
                                            // label="Metric Value"
                                            placeholder='Metric Value *'
                                            fullWidth
                                            required
                                            id="metricValue"
                                            name="metricValue"
                                            value={userData.metricValue || ''}
                                            onChange={handleChange}
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                            error={!!errors.metricValue}
                                            helperText={errors.metricValue}
                                        />
                                    </td>
                                </tr>
                            </Grid>
                        </Grid>
                    </tbody>
                </table>

            </div>
        </LocalizationProvider>
    );
};

export default AddProductOverView;
