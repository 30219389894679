import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import { Services } from 'Services';
import UserClasses from '../users/Users.module.css';
import { Helmet } from 'react-helmet';
import { IoIosAdd } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import TeamClasses from './AdminSettings.module.css';
import { toast } from 'react-toastify';
import { AccessControl } from '../Util/AccessControl';
import { AccessKeys } from 'Routes/config';

const TeamSettings = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [editedEmail, setEditedEmail] = useState('');

    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.getTeamMembers(token);

                if (response.Status === 1) {
                    setTeamMembers(response.teamMembers);

                    if (response.teamMembers.length > 0) {
                        setSelectedMember(response.teamMembers[0].adminId);
                    }
                } else {
                    console.error('Error fetching team members:', response.Message);
                }
            } catch (error) {
                console.error('Error fetching team members:', error);
            }
        };

        fetchTeamMembers();
    }, []);

    const getPermissionDetails = (adminId) => {
        const selectedTeamMember = teamMembers.find((member) => member.adminId === adminId);
        return selectedTeamMember?.permissions;
    };

    const renderCheckbox = (value, onChangeHandler) => {
        // Determine whether the checkbox should be disabled based on the edit permission
        const isDisabled = AccessControl(AccessKeys.Permissions)?.edit !== 1;

        // Render the checkbox with the provided value and onChangeHandler, and set its disabled attribute accordingly
        return (
            <input
                type="checkbox"
                checked={value}
                onChange={onChangeHandler}
                disabled={isDisabled}
            />
        );
    };

    const handleCheckboxChange = (module, permission) => {

        setTeamMembers((prevMembers) => {
            const updatedMembers = [...prevMembers];
            const selectedMemberIndex = updatedMembers.findIndex((member) => member.adminId === selectedMember);
            const permissionIndex = updatedMembers[selectedMemberIndex].permissions?.findIndex(item => item.name == module)
            console.log(updatedMembers[selectedMemberIndex].permissions[permissionIndex])
            updatedMembers[selectedMemberIndex].permissions[permissionIndex].Permissions[permission] = updatedMembers[selectedMemberIndex].permissions[permissionIndex].Permissions[permission] == 0 ? 1 : 0

            // updatedPermissions[module][permission] = !updatedPermissions[module][permission];

            // updatedMembers[selectedMemberIndex].permissions = updatedPermissions;

            console.log(updatedMembers)
            return updatedMembers;
        });
    };
    const renderPermissionsTable = (permissions) => {
        if (!permissions) {
            return <p>No permissions available.</p>;
        }
        console.log(permissions)
        const headers = Object.keys(permissions);
        const rows = permissions.map((header, index) => (
            <tr key={index}>
                <td>{header.name}</td>
                <td>{renderCheckbox(header.Permissions.edit, () => handleCheckboxChange(header.name, "edit"))}</td>
                <td>{renderCheckbox(header.Permissions.read, () => handleCheckboxChange(header.name, "read"))}</td>
                <td>{renderCheckbox(header.Permissions.write, () => handleCheckboxChange(header.name, "write"))}</td>
            </tr>
        ));

        return (
            <div style={{ overflowY: 'hidden' }}>
                <table className={UserClasses['Table']}>
                    <thead>
                        <tr>
                            <th>Module</th>
                            <th>Edit</th>
                            <th>Read</th>
                            <th>Write</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>
            </div>
        );
    };

    // const handleNameChange = (e) => {
    //     setEditedName(e.target.value);
    // };

    // const handleEmailChange = (e) => {
    //     setEditedEmail(e.target.value);
    // };

    const handleUpdateButtonClick = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const updatedPermissions = getPermissionDetails(selectedMember);

            const response = await Services.updateTeamPermissions(
                {
                    adminId: selectedMember,
                    // name: editedName, // Include editedName if needed
                    // email: editedEmail, // Include editedEmail if needed
                    permissions: updatedPermissions,
                },
                token,
            );

            if (response.Status === 1) {
                console.log('Permissions updated successfully:', response.Message);

                toast.success(response.Message)

                setTeamMembers((prevMembers) =>
                    prevMembers.map((member) =>
                        member.adminId === selectedMember
                            ? { ...member, permissions: updatedPermissions }
                            : member
                    )
                );

            } else {
                console.error('Error updating permissions:', response.Message);
            }
        } catch (error) {
            console.error('Error updating permissions:', error);
        }
    };

    const handleDeleteButtonClick = async () => {
        const userConfirmed = window.confirm('Are you sure you want to delete this team member?');
        if (userConfirmed) {
            try {
                const token = { Authorization: `token ${localStorage.getItem('Token')}` };

                if (selectedMember) {
                    const response = await Services.deleteTeamMember(selectedMember, token);

                    if (response.Status === 1) {
                        console.log('Team member deleted successfully:', response.Message);

                        toast.error(response.Message)

                        setTeamMembers((prevMembers) =>
                            prevMembers.filter((member) => member.adminId !== selectedMember)
                        );

                        // Optionally, reset selectedMember to the first member after deletion
                        if (teamMembers.length > 0) {
                            setSelectedMember(teamMembers[0].adminId);
                        }

                    } else {
                        console.error('Error deleting team member:', response.Message);
                    }
                }
            } catch (error) {
                console.error('Error deleting team member:', error);
            }
        }
    };


    return (
        <Layout Active={'Permissions'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Permissions</title>
            </Helmet>
            <div className={UserClasses['main-data-tab']}>
                <div className={UserClasses['users-main']}>
                    <div className={TeamClasses['team-mem-drop']}>
                        {AccessControl(AccessKeys.Permissions)?.edit === 1 && (
                            <div className={UserClasses['search-main']}>
                                {AccessControl(AccessKeys.Permissions)?.write === 1 && (
                                    <div className={UserClasses['add-users-btn']}>
                                        <NavLink className={UserClasses['navlink']} to={`/create-team-member`}>
                                            <button className={UserClasses['add-user']}>
                                                <IoIosAdd className={UserClasses['add-plus']} />
                                                Create Team Member
                                            </button>
                                        </NavLink>
                                    </div>
                                )}
                                {AccessControl(AccessKeys.Permissions)?.edit === 1 && (
                                    <div className={TeamClasses['team-drop']}>
                                        <select className={TeamClasses['team-drop-opt']} onChange={(e) => setSelectedMember(parseInt(e.target.value))} value={selectedMember}>
                                            {teamMembers.map((teamMember) => (
                                                <option key={teamMember.adminId} value={teamMember.adminId}>
                                                    {teamMember.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={UserClasses['users-sub-one']}>
                        <div className={UserClasses['users-sub-two']}>
                            <div className={UserClasses['users-div']}>
                                <h4 className={UserClasses['users-heading-one']}>Role Based Access</h4>
                            </div>
                            <div className={UserClasses['users-heading-two']}>
                                {selectedMember && (
                                    <div>
                                        <div>
                                            <p>{renderPermissionsTable(getPermissionDetails(selectedMember))}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {AccessControl(AccessKeys.Permissions)?.edit === 1 && (
                        <div className={TeamClasses['upd-del-block']}>
                            <button className={TeamClasses['roles-upd-btn']} onClick={handleUpdateButtonClick}>UPDATE</button>
                            <button className={TeamClasses['team-del-btn']} onClick={handleDeleteButtonClick}>DELETE</button>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default TeamSettings;
