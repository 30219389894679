import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import { Services } from 'Services';
import { Skeleton } from '@mui/material';

const ProductPricing = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getProductDetails(productId, token);
                setProduct(response.product); // Adjust this line based on your API response structure
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [productId]);

    if (loading) {
        // Show skeleton loading state while fetching data
        return (
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>Basic Pricing</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="2">
                            <div style={{padding: '5px'}} className={ViewUserClasses['skeleton-viewuseroverview']}>
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!product) {
        return <div>No product found.</div>;
    }

    return (
        <div>
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>Basic Pricing</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>MRP (per unit)</td>
                        <td className={ViewUserClasses['table-value']}>{product.productPrice}</td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Selling Price (per unit)</td>
                        <td className={ViewUserClasses['table-value']}>{product.productDiscountPrice}</td>
                    </tr>
                </tbody>
            </table>
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="5" className={ViewUserClasses['table-header']}>Bulk Pricing</th>
                    </tr>
                    <tr>
                        <th className={ViewUserClasses['data-th-variants']}>Qty</th>
                        <th className={ViewUserClasses['data-th-variants']}>Price (per unit)</th>
                        <th className={ViewUserClasses['data-th-variants']}>Purchase Price (per unit)</th>
                        <th className={ViewUserClasses['data-th-variants']}>Discount %</th>
                        <th className={ViewUserClasses['data-th-variants']}>Margin Profit %</th>
                    </tr>
                </thead>
                <tbody>
                    {product.ProductPriceVariants.map((variant, index) => (
                        <tr key={index}>
                            <td className={ViewUserClasses['data-variants']}>{variant.aboveQty}</td>
                            <td className={ViewUserClasses['data-variants']}>₹{variant.price}</td>
                            <td className={ViewUserClasses['data-variants']}>₹{variant.price}</td>
                            <td className={ViewUserClasses['data-variants']}>{Math.round((product.productPrice - variant.price) / product.productPrice * 100)}% off</td>
                            <td className={ViewUserClasses['data-variants']}>{Math.round((product.productDiscountPrice - variant.price) / product.productDiscountPrice * 100)}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProductPricing;
