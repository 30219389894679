import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import { Services } from 'Services';
import { Skeleton, Button, TextField } from '@mui/material';
import EditProdClasses from '../../Product/editProduct/EditProduct.module.css'
import { Success } from 'Util/Toast';

const EditProductPricing = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getProductDetails(productId, token);
                setProduct(response.product); // Adjust this line based on your API response structure
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [productId]);

    const handleInputChange = (e, field, index = null) => {
        const value = e.target.value;
        if (index !== null) {
            // Update bulk pricing variants
            const updatedVariants = product.ProductPriceVariants.map((variant, i) => {
                if (i === index) {
                    return { ...variant, [field]: value };
                }
                return variant;
            });
            setProduct({ ...product, ProductPriceVariants: updatedVariants });
        } else {
            // Update product details
            setProduct({ ...product, [field]: value });
        }
    };

    const stripUrlToEndpoint = (url) => {
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1];
    };

    const handleSave = async () => {
        const updatedProduct = {
            ...product,
            productImage: product.productImage.map(img => stripUrlToEndpoint(img))
        };

        try {
            const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
            const response = await Services.editProduct(productId, JSON.stringify(updatedProduct), token);
            if (response.Status === 1) {
                navigate('/products');
                Success(response.Message)
            } else {
                setError('Failed to update product');
            }
        } catch (err) {
            setError(err.message);
        }
    };

    if (loading) {
        // Show skeleton loading state while fetching data
        return (
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>Basic Pricing</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="2">
                            <div style={{ padding: '5px' }} className={ViewUserClasses['skeleton-viewuseroverview']}>
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                                <Skeleton variant="rectangular" width={300} height={30} />
                                <Skeleton variant="rectangular" width={640} height={30} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!product) {
        return <div>No product found.</div>;
    }

    return (
        <div>
            <div className={EditProdClasses['main-prod-over-btn']}>
                <button className={EditProdClasses['update-prod-overview-btn']} onClick={handleSave}>
                    Save Changes
                </button>
            </div>
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>Basic Pricing</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>MRP (per unit)</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                value={product.productPrice}
                                onChange={(e) => handleInputChange(e, 'productPrice')}
                                variant="outlined"
                                size="small"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={ViewUserClasses['table-label']}>Selling Price (per unit)</td>
                        <td className={ViewUserClasses['table-value']}>
                            <TextField
                                value={product.productDiscountPrice}
                                onChange={(e) => handleInputChange(e, 'productDiscountPrice')}
                                variant="outlined"
                                size="small"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="5" className={ViewUserClasses['table-header']}>Bulk Pricing</th>
                    </tr>
                    <tr>
                        <th className={ViewUserClasses['data-th-variants']}>Qty</th>
                        <th className={ViewUserClasses['data-th-variants']}>Price (per unit)</th>
                        <th className={ViewUserClasses['data-th-variants']}>Purchase Price (per unit)</th>
                        <th className={ViewUserClasses['data-th-variants']}>Discount %</th>
                        <th className={ViewUserClasses['data-th-variants']}>Margin Profit %</th>
                    </tr>
                </thead>
                <tbody>
                    {product.ProductPriceVariants.map((variant, index) => (
                        <tr key={index}>
                            <td className={ViewUserClasses['data-variants']}>
                                <TextField
                                    value={variant.aboveQty}
                                    onChange={(e) => handleInputChange(e, 'aboveQty', index)}
                                    variant="outlined"
                                    size="small"
                                />
                            </td>
                            <td className={ViewUserClasses['data-variants']}>
                                <TextField
                                    value={variant.price}
                                    onChange={(e) => handleInputChange(e, 'price', index)}
                                    variant="outlined"
                                    size="small"
                                />
                            </td>
                            <td className={ViewUserClasses['data-variants']}>₹{variant.price}</td>
                            <td className={ViewUserClasses['data-variants']}>{Math.round((product.productPrice - variant.price) / product.productPrice * 100)}% off</td>
                            <td className={ViewUserClasses['data-variants']}>{Math.round((product.productDiscountPrice - variant.price) / product.productDiscountPrice * 100)}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default EditProductPricing;
