import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, Tabs, Tab, Paper, InputBase } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import Layout from 'Components/Layout';
import SkeletonLoad from 'Components/TableLoad/load';
import { Services } from 'Services';
import CommonClasses from 'Styles/Common.module.css';
import ProductClasses from '../Product/Products.module.css';
import UserClasses from './Users.module.css';
import { BiPlus } from 'react-icons/bi';
import Orderclasses from '../orders/Orders.module.css';
import moment from 'moment';
import ViewUserClasses from '../users/viewUsers/ViewUsers.module.css';
import { Success } from 'Util/Toast';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [usersCount, setUsersCount] = useState({
        allUserCount: 0,
        deleteRequestCount: 0,
        newRequests: 0,
    });

    const navigate = useNavigate();

    useEffect(() => {
        fetchUsersByStatus(activeTab + 1);
    }, [activeTab]);

    useEffect(() => {
        filterUsers(searchQuery);
    }, [searchQuery, users]);

    const fetchUsersByStatus = async (status) => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getUsers(status, token);
            if (response.Status === 1) {
                setUsers(response.users);
                setFilteredUsers(response.users);
                setUsersCount(response.usersCount);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching users:', error);
            setLoading(false);
        }
    };

    const filterUsers = (query) => {
        const lowerCaseQuery = query.toLowerCase();
        const filtered = users.filter(user =>
            user.firstName.toLowerCase().includes(lowerCaseQuery) ||
            user.mobileNumber.toLowerCase().includes(lowerCaseQuery)
        );
        setFilteredUsers(filtered);
    };

    const handleMenuOpen = (event, userId) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setSelectedUserId(userId);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleEditUser = () => {
        const user = users.find(u => u.userId === selectedUserId);
        navigate(`/edit-users/${selectedUserId}`, { state: { user } });
        handleMenuClose();
    };

    const handleOpenDeleteDialog = (params) => {
        setDeleteDialogOpen(true);
        handleMenuClose();
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };

    const handleDeleteUser = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            await Services.deleteUser(selectedUserId, token);
            setUsers(users.filter(user => user.userId !== selectedUserId));
            handleCloseDeleteDialog();
            Success("User Deleted");
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleAcceptDeleteRequest = async (userId) => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            await Services.acceptUserDeleteRequest(userId, token);
            setUsers(users.filter(user => user.userId !== userId));
            Success("User Deleted");
        } catch (error) {
            console.error('Error accepting delete request:', error);
        }
    };

    const formatDate = (dateString) => {
        return moment(dateString).format("DD-MM-YYYY");
    };

    const commonColumns = [
        { field: 'firstName', headerName: 'Name', width: 200 },
        { field: 'userId', headerName: 'UserId', width: 200 },
        { field: 'company', headerName: 'Company', width: 200 },
        { field: 'mobileNumber', headerName: 'Mobile Number', width: 200 },
        { field: 'createdAt', headerName: 'Date Joined', width: 200, valueFormatter: (params) => formatDate(params) },
        {
            field: 'isDocumentsVerified',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => (
                <div
                    style={{
                        backgroundColor: params.value === 1 ? '#79f4a3' : '#fef987',
                        color: 'Black',
                        width: '100px',
                        fontWeight: 'bold',
                        fontSize: '0.875rem',
                        borderRadius: '4px',
                        textAlign: 'center',
                        marginTop: '10px',
                        lineHeight: 'normal',
                        padding: '10px'
                    }}
                >
                    {params.value === 1 ? 'Verified' : 'Not Verified'}
                </div>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <div>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuOpen(event, params.row.userId)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                        sx={{ boxShadow: 'none' }}
                    >
                        <MenuItem onClick={handleEditUser}>Edit</MenuItem>
                        <MenuItem onClick={() => handleOpenDeleteDialog(params)}>Delete</MenuItem>
                    </Menu>
                </div>
            ),
        }
    ];

    const deleteRequestColumn = {
        field: 'acceptDeleteRequest',
        headerName: 'Accept Delete Request',
        width: 200,
        renderCell: (params) => (
            <button
                style={{
                    backgroundColor: 'black',
                    color: 'white',
                    width: '100px',
                    fontWeight: 'bold',
                    fontSize: '0.875rem',
                    borderRadius: '4px',
                    textAlign: 'center',
                    marginTop: '10px',
                    lineHeight: 'normal',
                    padding: '10px'
                }}
                variant="contained"
                color="secondary"
                onClick={() => handleAcceptDeleteRequest(params.row.userId)}
            >
                Accept
            </button>
        ),
    };

    const columns = activeTab === 2 ? [...commonColumns, deleteRequestColumn] : commonColumns;

    const handleSelectionModelChange = (newSelection) => {
        setSelectedUsers(newSelection);
    };

    const handleRowClick = (params) => {
        navigate(`/view-users/${params.row.userId}`, { state: { user: params.row } });
    };

    return (
        <Layout Active={'Users'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Users</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={ViewUserClasses['search-new-btn']}>
                    <div>
                        <h3 className={ProductClasses['main-header']}>Users<span className={ProductClasses['count-of-users']}>({usersCount.allUserCount + usersCount.newRequests + usersCount.deleteRequestCount})</span></h3>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', width: '400px' }}>
                        <SearchIcon className={ViewUserClasses['searchicon']} />
                        <InputBase
                            placeholder="User Name/Mobile Number"
                            inputProps={{ 'aria-label': 'search users' }}
                            style={{
                                marginLeft: '10px',
                                flex: 1,
                                padding: '5px 35px',
                                border: '1px solid #ddd',
                                borderRadius: '4px',
                                textIndent: '60px'
                            }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>

                <div className={CommonClasses['control-btns']}>
                    <Button style={{ marginBottom: '20px' }}
                        className={CommonClasses['button-for-add']}
                        onClick={() => {
                            navigate("/adduser");
                        }}>
                        <BiPlus size={20} /> Add User
                    </Button>
                </div>
                <Paper square className={Orderclasses['paper-square']}>
                    <Tabs
                        value={activeTab}
                        textColor="secondary"
                        indicatorColor="red"
                        TabIndicatorProps={{style: {backgroundColor: 'black'}}}
                        aria-label="secondary tabs example"
                        onChange={(event, newValue) => setActiveTab(newValue)}
                    >
                        <Tab label={`All Users (${usersCount.allUserCount})`} />
                        <Tab label={`New Requests (${usersCount.newRequests})`} />
                        <Tab label={`Delete Requests (${usersCount.deleteRequestCount})`} />
                    </Tabs>
                </Paper>
                {loading ? (
                    <SkeletonLoad
                        TableHead={["NAME", "USER ID", "COMPANY", "DATE JOINED", "STATUS"]}
                    />
                ) : filteredUsers.length > 0 ? (
                    <div className={CommonClasses['Table']} style={{ maxHeight: 600 }}>
                        <DataGrid
                            rows={filteredUsers}
                            columns={columns}
                            pageSize={pageSize}
                            rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            pagination
                            getRowId={(row) => row.userId}
                            checkboxSelection
                            onSelectionModelChange={handleSelectionModelChange}
                            onRowClick={handleRowClick}
                        />
                        {selectedUsers.length > 0 && (
                            <div>
                                <p>Selected Users: {selectedUsers.join(', ')}</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <p>No Users Found</p>
                    </div>
                )}

                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete User"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this user?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteUser} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Layout>
    );
};

export default Users;
