import React, { useState, useEffect } from 'react';
import { MenuItem, TextField, Button } from '@mui/material';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import { useParams } from 'react-router-dom';
import { Services } from 'Services';
import { Success, Error } from 'Util/Toast'; // Assuming you have a Toast utility for notifications
import EditProdClasses from '../../Product/editProduct/EditProduct.module.css';
import EditOrderGeneralClasses from './EditOrder.module.css';
import { Hourglass } from 'react-loader-spinner';

const EditOrderGeneral = () => {
    const { orderId } = useParams();
    const [paymentType, setPaymentType] = useState(1); // Default to Cash
    const [orderData, setOrderData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    // Function to simulate a delay
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
        const fetchOrderDetails = async () => {
            try {
                const response = await Services.getOrderDetails(orderId, token);
                await delay(2000); // 1-second delay
                setOrderData(response.data);
                setPaymentType(response.data.paymentType);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching order data', error);
                setIsLoading(false);
            }
        };

        fetchOrderDetails();
    }, [orderId]);

    const handlePaymentTypeChange = (event) => {
        setPaymentType(event.target.value);
    };

    const handleSave = async () => {
        const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
        const payload = {
            ...orderData,
            paymentType,
        };

        try {
            const response = await Services.editOrder(orderId, payload, token);
            console.log('Order updated successfully', response);
            Success(response.Message);
        } catch (error) {
            console.error('Error updating order', error);
            Error('Error updating order');
        }
    };

    if (isLoading) {
        return (
            <div className={EditOrderGeneralClasses['spinner-container']}>
                <Hourglass
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="hourglass-loading"
                    colors={['#23232A', '#60E19B']}
                />
            </div>
        );
    }

    return (
        <div>
            <div className={EditProdClasses['main-prod-over-btn']}>
                <button className={EditOrderGeneralClasses['general-savechanges']} onClick={handleSave}>Save Changes</button>
            </div>
            <table className={AddUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={AddUserClasses['table-header']}>General</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={AddUserClasses['table-label']}>Payment Type</td>
                        <td className={AddUserClasses['table-value']}>
                            <TextField
                                required
                                id="paymentType"
                                name="paymentType"
                                select
                                variant="outlined"
                                margin="dense"
                                className={AddUserClasses['table-textfield-all']}
                                value={paymentType}
                                onChange={handlePaymentTypeChange}
                            >
                                <MenuItem value={1}>Cash</MenuItem>
                                <MenuItem value={2}>Online</MenuItem>
                            </TextField>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default EditOrderGeneral;
