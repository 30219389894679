import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, MenuItem, Select, FormControl, InputLabel, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { Services } from 'Services';
import { useParams, useNavigate } from 'react-router-dom';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import EditProdClasses from './EditProduct.module.css'
import { Hourglass } from 'react-loader-spinner';
import EditOrderGeneralClasses from '../../orders/editOrder/EditOrder.module.css'
import { Success } from 'Util/Toast';


const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const EditProductDescription = () => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const [productData, setProductData] = useState({
        productName: '',
        productCategoryId: '',
        productPrice: '',
        discount: '',
        stockQty: '',
        productDiscountPrice: '',
        status: '',
        brandId: '',
        typeId: '',
        productDealId: [],
        manufacturer: '',
        manufacturerDate: null,
        expiryDate: null,
        estimatedDeliveryDate: null,
        sku: '',
        unitsSold: '',
        metricType: '',
        metricValue: '',
        productDescription: '',
        productUse: '',
        productBenefits: '',
        productImage: []  // Add this line to include productImage in the initial state
    });
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState(null);
    const [brands, setBrands] = useState([]);
    const [types, setTypes] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [homeDeals, setHomeDeals] = useState([]);

    useEffect(() => {
        fetchBrands();
        fetchTypes();
        fetchProductCategories();
        fetchHomeDeals();

    }, []);
    useEffect(() => {
        if (types.length > 0 && brands.length > 0 && productCategories.length > 0 && homeDeals.length > 0) {
            fetchProductDetails()
        }
    }, [types, brands, productCategories, homeDeals])

    const fetchBrands = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getBrands(token);
            if (response.Status === 1) {
                setBrands(response.brands);
            } else {
                console.error('Error fetching brands:', response.Message);
            }
        } catch (error) {
            console.error('Error fetching brands:', error);
        }
    };

    const fetchTypes = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getTypes(token);
            if (response.Status === 1) {
                setTypes(response.types);
            } else {
                console.error('Error fetching types:', response.Message);
            }
        } catch (error) {
            console.error('Error fetching types:', error);
        }
    };

    const fetchProductCategories = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getCategories(token);
            if (response.Status === 1) {
                setProductCategories(response.productCategories);
            } else {
                console.error('Error fetching productCategories:', response.Message);
            }
        } catch (error) {
            console.error('Error fetching productCategories:', error);
        }
    };

    const fetchHomeDeals = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getDeals(token);
            if (response.Status === 1) {
                setHomeDeals(response.homeDeals);
            } else {
                console.error('Error fetching homeDeals:', response.Message);
            }
        } catch (error) {
            console.error('Error fetching homeDeals:', error);
        }
    };

    const fetchProductDetails = async () => {
        setLoading(true)
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getProductDetails(productId, token);
            if (response.Status === 1) {
                await delay(2000); // 1-second delay
                const product = response.product;
                const brand = brands.find(b => b.brandName === product.brand);
                const type = types.find(t => t.typeName === product.type);
                setProductData({
                    ...product,
                    brandId: brand ? brand.brandId : '',
                    typeId: type ? type.typeId : '',
                    productDealId: product.productDealId,
                    manufacturerDate: product.manufacturerDate ? dayjs(product.manufacturerDate) : null,
                    expiryDate: product.expiryDate ? dayjs(product.expiryDate) : null,
                    estimatedDeliveryDate: product.estimatedDeliveryDate ? dayjs(product.estimatedDeliveryDate) : null,
                });
            } else {
                setError('Failed to fetch product details');
            }
        } catch (error) {
            setError('Error fetching product details');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProductData(prevProductData => ({
            ...prevProductData,
            [name]: value
        }));

        // Remove error when user starts typing
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleDateChange = (name, date) => {
        setProductData(prevProductData => ({
            ...prevProductData,
            [name]: date
        }));

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const stripUrlToEndpoint = (url) => {
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1];
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const updatedProductData = {
            ...productData,
            productImage: productData.productImage.map(img => stripUrlToEndpoint(img))
        };

        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.editProduct(productId, JSON.stringify(updatedProductData), token);
            if (response.Status === 1) {
                navigate('/products');
                Success(response.Message)
            } else {
                setError('Failed to update product');
            }
        } catch (error) {
            setError('Error updating product');
        }
    };

    if (loading) {
        return (
            <div className={EditOrderGeneralClasses['spinner-container']}>
                <Hourglass
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="hourglass-loading"
                    colors={['#23232A', '#60E19B']}
                />
            </div>
        );
    }

    if (error) {
        return <Typography>{error}</Typography>;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
                <form onSubmit={handleSubmit}>
                    <div className={EditProdClasses['main-prod-over-btn']}>
                        <button className={EditProdClasses['update-prod-overview-btn']} type="submit">Save Changes</button>
                    </div>
                    <table className={AddUserClasses['user-table']}>
                        <thead>
                            <tr>
                                <th colSpan="2" className={AddUserClasses['table-header']}>Basic Product Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={AddUserClasses['table-label']}>Description</td>
                                <td className={AddUserClasses['table-value']}>
                                    <TextField
                                        required
                                        id="productDescription"
                                        name="productDescription"
                                        value={productData.productDescription || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="dense"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        className={AddUserClasses['table-textfield-all']}
                                        error={!!errors.productDescription}
                                        helperText={errors.productDescription}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={AddUserClasses['table-label']}>Product Use</td>
                                <td className={AddUserClasses['table-value']}>
                                    <TextField
                                        required
                                        id="productUse"
                                        name="productUse"
                                        value={productData.productUse || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="dense"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        className={AddUserClasses['table-textfield-all']}
                                        error={!!errors.productUse}
                                        helperText={errors.productUse}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={AddUserClasses['table-label']}>Product Benefits</td>
                                <td className={AddUserClasses['table-value']}>
                                    <TextField
                                        required
                                        id="productBenefits"
                                        name="productBenefits"
                                        value={productData.productBenefits || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="dense"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        className={AddUserClasses['table-textfield-all']}
                                        error={!!errors.productBenefits}
                                        helperText={errors.productBenefits}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </LocalizationProvider>
    );
};

export default EditProductDescription;
