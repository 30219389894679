import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Step, StepLabel, Stepper, Breadcrumbs, Link, Typography, Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import Layout from 'Components/Layout';
import ViewUserClasses from '../../users/viewUsers/ViewUsers.module.css';
import CommonClasses from '../../../Styles/Common.module.css';
import AddUserClasses from '../../users/addUser/AddUser.module.css';
import { Services } from 'Services';
import AddProductOverview from './AddProductOverview';
import AddProductDescription from './AddProductDescription';
import AddProductGallery from './AddProductGallery';
import AddProductPricing from './AddProductPricing';

const AddProduct = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [userData, setUserData] = useState({
        productName: '',
        brandName: '',
        typeName: '',
        productCategoryId: '',
        manufacturer: '',
        manufacturerDate: '',
        expiryDate: '',
        productPrice: '',
        productDiscountPrice: '',
        discount: '',
        productDealId: '',
        stockQty: '',
        unitsSold: '',
        sku: '',
        packageSize: '',
        estimatedDeliveryDays: '',
        estimatedDeliveryText: '',
        estimatedDeliveryDate: '',
        metricType: '',
        metricValue: '',
        productDescription: '',
        productUse: '',
        productBenefits: '',
        productImage: [],
        priceTiers: [],
        lastMinutePick: 0,
        popularProduct: 0,
        moq: 0,
        productVideos: [], // Ensure productVideos is initialized
    });
    const [errors, setErrors] = useState({});
      
    const steps = ['Overview', 'Description', 'Gallery', 'Pricing'];

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/products')}>
            Products
        </Link>,
        <Typography key="3" color="text.primary">
            Add Product
        </Typography>,
    ];

    const validate = (name, value) => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            if (!value) {
                newErrors[name] = `${name} is required`;
            } else {
                delete newErrors[name];
            }
            return newErrors;
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (activeStep === 0) {
            if (!userData.productName) newErrors.productName = 'Product Name is required';
            if (!userData.brandName) newErrors.brandName = 'Brand Name is required';
            if (!userData.typeName) newErrors.typeName = 'Type Name is required';
            if (!userData.productCategoryId) newErrors.productCategoryId = 'Category Name is required';
            if (!userData.manufacturer) newErrors.manufacturer = 'Manufacturer is required';
            if (!userData.manufacturerDate) newErrors.manufacturerDate = 'Manufacturing Date is required';
            if (!userData.expiryDate) newErrors.expiryDate = 'Expiry Date is required';
            if (!userData.productPrice) newErrors.productPrice = 'Product Price is required';
            // if (!userData.productDiscountPrice) newErrors.productDiscountPrice = 'Product Discount Price is required';
            // if (!userData.discount) newErrors.discount = 'Discount is required';
            if (!userData.productDealId) newErrors.productDealId = 'Deal Name is required';
            if (!userData.stockQty) newErrors.stockQty = 'Stock Quantity is required';
            if (!userData.unitsSold) newErrors.unitsSold = 'Units Sold is required';
            if (!userData.sku) newErrors.sku = 'Sku is required';
            if (!userData.packageSize) newErrors.packageSize = 'Package Size is required';
            if (!userData.isActive) newErrors.isActive = 'Is Active is required';
            // if (!userData.estimatedDeliveryDate) newErrors.estimatedDeliveryDate = 'Estimated Delivery Date is required';
            if (!userData.estimatedDeliveryDays) newErrors.estimatedDeliveryDays = 'Estimated Delivery Days is required';
            if (!userData.estimatedDeliveryText) newErrors.estimatedDeliveryText = 'Estimated Delivery Text is required';
            if (!userData.metricType) newErrors.metricType = 'Metric Type is required';
            if (!userData.metricValue) newErrors.metricValue = 'Metric Value is required';
        }
        if (activeStep === 1) {
            if (!userData.productDescription) newErrors.productDescription = 'Product Description is required';
            if (!userData.productUse) newErrors.productUse = 'Product Use is required';
            if (!userData.productBenefits) newErrors.productBenefits = 'Product Benefits is required';
        }
        if (activeStep === 2) {
            if (!userData.productImage.length) newErrors.productImage = 'Product Image is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        if (validateForm()) {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleSaveChanges = async () => {
        try {
            const estimatedDeliveryDate = `${userData.estimatedDeliveryText} ${userData.estimatedDeliveryDays}`;
            const payload = {
                ...userData,
                brand: userData.brandName,
                type: userData.typeName,
                productCategoryId: userData.productCategoryId,
                productDealId: userData.productDealId,
                estimatedDeliveryDate,
                productImage: userData.productImage,
                productVideo: userData.productVideos, // Include productVideos in the payload
                ProductPriceVariants: userData.priceTiers, // Map priceTiers to ProductPriceVariants
                lastMinutePicks: userData.lastMinutePick, // Map lastMinutePick to lastMinutePicks
                popularProducts: userData.popularProduct, // Map popularProduct to popularProducts
                moq: userData.moq, // Include moq as it is
                isActive: userData.isActive === 1 ? 1 : 0 // Convert isActive to 1 or 0
            };
    
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.addProduct(payload, token);
            console.log('Product added:', response);
            navigate('/products');
        } catch (error) {
            console.error('Error adding product:', error);
        }
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <AddProductOverview userData={userData} setUserData={setUserData} errors={errors} validate={validate} />;
            case 1:
                return <AddProductDescription userData={userData} setUserData={setUserData} errors={errors} validate={validate} />;
            case 2:
                return <AddProductGallery userData={userData} setUserData={setUserData} errors={errors} validate={validate} />;
            case 3:
                return <AddProductPricing userData={userData} setUserData={setUserData} />;
            default:
                return 'Unknown stepIndex';
        }
    };

    return (
        <Layout Active={'Products'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Users</title>
            </Helmet>
            <div className={AddUserClasses['main-of-tables']}>
                <div className={AddUserClasses['new-main-div']}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>
                <div className={AddUserClasses['main-right-add-btns']}>
                    {activeStep !== 0 && (
                        <button className={AddUserClasses['main-right-cancel-btns']} onClick={handleBack} style={{ marginRight: 10 }}>
                            Back
                        </button>
                    )}
                    {activeStep !== steps.length - 1 ? (
                        <button className={AddUserClasses['next-stepper-btn']} variant="contained" onClick={handleNext}>
                            Continue to next step
                        </button>
                    ) : (
                        <button className={AddUserClasses['main-right-save-btns']} onClick={handleSaveChanges}>
                            Save Changes
                        </button>
                    )}
                </div>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div className={AddUserClasses['main-btn-div']}>
                    {getStepContent(activeStep)}
                </div>
            </div>
        </Layout>
    );
};

export default AddProduct;
