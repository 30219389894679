import React, { useEffect, useState } from 'react';
import ViewUserClasses from './ViewUsers.module.css';
import { useParams } from 'react-router-dom';
import { Services } from 'Services';
import { Skeleton, Stack } from '@mui/material';

const UserAddressTable = () => {
    const { userId } = useParams();
    const [addresses, setAddresses] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getUserDetails(userId, token);
                setAddresses(response.addresses); // Adjust this line based on your API response structure
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [userId]);

    if (loading) {
        return (
            <table className={ViewUserClasses['user-table']}>
                <thead>
                    <tr>
                        <th colSpan="2" className={ViewUserClasses['table-header']}>Address</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="2">
                            <div>
                                <Stack spacing={1}>
                                    <div style={{padding: '5px'}}>
                                        <Skeleton variant="rectangular" style={{ marginBottom: '5px' }} height={30} />
                                        <Skeleton variant="rectangular" style={{ marginBottom: '5px' }} height={30} />
                                        <Skeleton variant="rectangular" style={{ marginBottom: '5px' }} height={30} />
                                        <Skeleton variant="rectangular" style={{ marginBottom: '5px' }} height={30} />
                                        <Skeleton variant="rectangular" style={{ marginBottom: '5px' }} height={30} />
                                        <Skeleton variant="rectangular" style={{ marginBottom: '5px' }} height={30} />
                                    </div>
                                </Stack>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!addresses) {
        return <div>No user found.</div>;
    }


    return (
        <table className={ViewUserClasses['user-table']}>
            <thead>
                <tr>
                    <th colSpan="2" className={ViewUserClasses['table-header']}>Address</th>
                </tr>
            </thead>
            <tbody>
                {addresses.map((address, index) => (
                    <div className={ViewUserClasses['each-address']}>
                        <tr key={index}>
                            <td className={ViewUserClasses['table-address-value']}>{address.firstName || "something"}</td>
                        </tr>
                        <tr>
                            <td className={ViewUserClasses['table-address-value']}>{address.company || "something"}</td>
                        </tr>
                        <tr>
                            <td className={ViewUserClasses['table-address-value']}>{address.flatNo || "something"}, {address.deliveryAddress || "something"}, {address.area || "something"}, {address.city || "something"}, {address.state || "something"}, {address.pincode || "something"}</td>
                        </tr>
                        <tr>
                            <td className={ViewUserClasses['table-address-value']}>{address.mobileNumber || "something"}</td>
                        </tr>
                        <tr>
                            <td className={ViewUserClasses['table-address-value']}>Track Location({address.deliveryAddress || "something"})/Coordinates : {address.latitude},{address.longitude}</td>
                        </tr>
                    </div>
                ))}
            </tbody>
        </table>
    );
};

export default UserAddressTable;
