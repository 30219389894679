import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiPlus } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import CommonClasses from "Styles/Common.module.css";
import ProductClasses from '../Product/Products.module.css';
import SkeletonLoad from "Components/TableLoad/load";
import { Services } from 'Services';
import { Modal, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Success, Error_Dark } from 'Util/Toast';
import Layout from 'Components/Layout';

const ProductBrand = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [brands, setBrands] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [newBrand, setNewBrand] = useState('');
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [deleteBrandId, setDeleteBrandId] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);

    useEffect(() => {
        fetchBrands();
    }, []);

    const fetchBrands = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getBrands(token);
            if (response.Status === 1) {
                setBrands(response.brands);
            } else {
                console.error('Error fetching brands:', response.Message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching brands:', error);
            setLoading(false);
        }
    };

    const handleOpenModal = (brand) => {
        setSelectedBrand(brand || null);
        setNewBrand(brand ? brand.brandName : '');
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedBrand(null);
        setNewBrand('');
        setOpenModal(false);
    };

    const handleAddBrand = async () => {
        let body = { brandName: newBrand };
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.addBrand(JSON.stringify(body), token);
            if (response.Status === 1) {
                fetchBrands();
                handleCloseModal();
                Success(response.Message);
            } else {
                console.error('Error adding brand:', response.Message);
            }
        } catch (error) {
            console.error('Error adding brand:', error);
        }
    };

    const handleEditBrand = async () => {
        let body = { brandName: newBrand };
        try {
            if (!selectedBrand) {
                console.error('No brand selected for editing.');
                return;
            }

            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.editBrand(selectedBrand.brandId, JSON.stringify(body), token);
            if (response.Status === 1) {
                fetchBrands();
                handleCloseModal();
                Success(response.Message);
            } else {
                console.error('Error updating brand:', response.Message);
            }
        } catch (error) {
            console.error('Error updating brand:', error);
        }
    };

    const handleDeleteBrand = (brandId) => {
        setDeleteBrandId(brandId);
        setDeleteConfirmation(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.deleteBrand(deleteBrandId, token);
            if (response.Status === 1) {
                fetchBrands();
                Success(response.Message);
                setDeleteConfirmation(false);
            } else {
                console.error('Error deleting brand:', response.Message);
            }
        } catch (error) {
            console.error('Error deleting brand:', error);
        }
    };

    const handleCancelDelete = () => {
        setDeleteConfirmation(false);
        setDeleteBrandId(null);
    };

    const handleMenuOpen = (event, brandId) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        setSelectedRowId(brandId);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setSelectedRowId(null);
    };

    const handleEdit = () => {
        const brand = brands.find(b => b.brandId === selectedRowId);
        handleOpenModal(brand);
        handleMenuClose();
    };

    const handleDelete = () => {
        handleDeleteBrand(selectedRowId);
        handleMenuClose();
    };

    const columns = [
        { field: 'brandName', headerName: 'Product Brand', width: 700 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 600,
            renderCell: (params) => (
                <div>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuOpen(event, params.row.brandId)}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                        sx={{ boxShadow: 'none' }}
                    >
                        <MenuItem onClick={handleEdit}>Edit</MenuItem>
                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    return (
        <Layout Active={"Product Brand"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Product Brands</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <h3 className={ProductClasses['main-header']}>Product Brands<span className={ProductClasses['count-of-users']}>({brands.length})</span></h3>
                <div className={CommonClasses['control-btns']}>
                    <Button style={{marginBottom: '20px'}}
                        className={CommonClasses['button-for-add']}
                        onClick={() => handleOpenModal(null)}
                    >
                        <BiPlus size={20} /> Add Product Brand
                    </Button>
                </div>
                <div>
                    {loading ? (
                        <SkeletonLoad
                            TableHead={["Product Brand", "Actions"]}
                        />
                    ) : brands.length > 0 ? (
                        <div className={CommonClasses["Table"]} style={{ maxHeight: 600 }}>
                            <DataGrid
                                rows={brands}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10, 25, 50, { value: -1, label: 'All' }]}
                                pagination
                                getRowId={(row) => row.brandId}
                                checkboxSelection
                                onSelectionModelChange={(newSelection) => {
                                    setSelectedRows(newSelection);
                                }}
                            />
                            {selectedRows.length > 0 && (
                                <div>
                                    <p>Selected Product Brands: {selectedRows.join(', ')}</p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <p>No Product Brands Found</p>
                        </div>
                    )}
                </div>
            </div>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className={ProductClasses.modal}>
                    <h2 id="modal-title">{selectedBrand ? 'Edit Product Brand' : 'Add Product Brand'}</h2>
                    <TextField
                        label="Product Brand"
                        value={newBrand}
                        onChange={(e) => setNewBrand(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <button className={ProductClasses['edit-btn']} onClick={selectedBrand ? handleEditBrand : handleAddBrand}>{selectedBrand ? 'Edit' : 'Add'}</button>
                    <button className={ProductClasses['del-btn']} onClick={handleCloseModal}>Cancel</button>
                </div>
            </Modal>

            <Dialog
                open={deleteConfirmation}
                onClose={handleCancelDelete}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this product brand?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default ProductBrand;
