import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Services } from 'Services';

const AddProductsDialog = ({ open, onClose, onAddProducts }) => {
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getAllProducts(token);
                if (response.Status === 1) {
                    setProducts(response.products);
                }
            } catch (err) {
                console.error(err);
            }
        };

        if (open) {
            fetchProducts();
        }
    }, [open]);

    const handleSelectProduct = (product) => {
        setSelectedProducts((prevSelected) => {
            if (prevSelected.some((p) => p.productId === product.productId)) {
                return prevSelected.filter((p) => p.productId !== product.productId);
            } else {
                return [...prevSelected, product];
            }
        });
    };

    const handleAddProducts = () => {
        onAddProducts(selectedProducts);
        onClose();
    };

    const filteredProducts = products.filter(
        (product) =>
            product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            product.manufacturer.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Add Products
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search product name / SKU / manufacturer"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ marginBottom: '16px' }}
                />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox"></TableCell>
                                <TableCell>Product Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>Stock</TableCell>
                                <TableCell>Selling price</TableCell>
                                <TableCell>Manufacturer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredProducts.map((product) => (
                                <TableRow key={product.productId}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedProducts.some((p) => p.productId === product.productId)}
                                            onChange={() => handleSelectProduct(product)}
                                        />
                                    </TableCell>
                                    <TableCell>{product.productId}</TableCell>
                                    <TableCell>{product.productName}</TableCell>
                                    <TableCell>{product.productCategoryId}</TableCell>
                                    <TableCell>{product.stockQty}</TableCell>
                                    <TableCell>{product.productDiscountPrice}</TableCell>
                                    <TableCell>{product.manufacturer}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleAddProducts} variant="contained" color="primary">
                    Add Selected
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddProductsDialog;
