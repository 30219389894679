import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import TutorClasses from '../../../Categories/addCategory/index.module.css';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import ReactLoading from 'react-loading';
import { BsCloudUpload } from 'react-icons/bs';
import S3 from 'react-s3';
import { Success, Error } from 'Util/Toast'; // Assuming Error is imported
import ProductClasses from '../../../Product/Products.module.css';
import CouponClasses from '../../../coupons/Coupon.module.css';
import { Breadcrumbs, Button, Link, Stack, Typography } from '@mui/material';
import ViewUserClasses from '../../../users/viewUsers/ViewUsers.module.css';
import AddUserClasses from '../../../users/addUser/AddUser.module.css';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

window.Buffer = window.Buffer || require('buffer').Buffer;

const CreateBanner = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [homeDeals, setHomeDeals] = useState([]); // State to hold homeDeals
    const [imageError, setImageError] = useState(''); // State to hold image error message

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'banners',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    const filehandleChange = async (event) => {
        const files = event.target.files[0];
        if (
            files.type === 'image/png' ||
            files.type === 'image/jpeg' ||
            files.type === 'image/webp'
        ) {
            try {
                const timestamp = Date.now();
                const newfile = new File(
                    [files],
                    `${timestamp}.${String(files.type).split('/')[1]}`
                );

                setLoading(true);
                const res = await S3.uploadFile(newfile, {
                    ...config,
                    dirName: 'banners',
                });
                setImage(res.location); // Update image state with the image URL
                setImagePreview(URL.createObjectURL(files)); // Set image preview
                setValue('image', `${timestamp}.${String(files.type).split('/')[1]}`);
                setLoading(false);
                setImageError(''); // Clear the image error message
            } catch (error) {
                console.error('Error uploading image:', error);
                setLoading(false);
                Error('Error uploading image');
            }
        } else {
            alert(`${files.name} is neither PNG nor jpeg`);
        }
    };

    const onSubmit = async (data) => {
        if (!image) {
            setImageError('Please upload an image');
            return;
        }

        setLoading(true);
        try {
            // Extract filename from image
            const filename = image.split('/').pop();

            // Prepare formData object with required keys
            const formData = {
                banner: filename, // Assuming 'banner' should be the image filename
                productDealId: parseInt(data.deal), // Convert 'deal' to integer if needed
            };

            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.addBanner(formData, token);
            
            if (response.Status === 1) {
                // Banner added successfully
                setLoading(false);
                navigate('/banners');
                Success(response.Message);
            } else {
                // Handle error
                setLoading(false);
                Error(response.Message);
            }
        } catch (error) {
            console.error('Error adding banner:', error);
            setLoading(false);
        }
    };

    const fetchHomeDeals = async () => {
        try {
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.getDeals(token);
            
            if (response.Status === 1) {
                setHomeDeals(response.homeDeals); // Set homeDeals in state
            } else {
                console.error('Error fetching homeDeals:', response.Message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching homeDeals:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHomeDeals();
    }, []);

    const fileuploadDesign = (
        <div style={{ border: 'none' }} className={TutorClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <div className={TutorClasses['upload-image']}>
                {imagePreview && <img src={imagePreview} alt="Selected" className={CouponClasses['uploaded-coupon-image']} />}
                {!imagePreview && (
                    <div className={TutorClasses['add-item']} onClick={() => document.getElementById("image").click()} style={{ border: 'none' }}>
                        <BsCloudUpload className={TutorClasses['upload-icon']} />
                        <h5>Drag Your Files here</h5>
                        <p>(Only *.jpeg, *.png will be accepted)</p>
                    </div>
                )}
                <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
            </div>
        </div>
    );

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" onClick={() => navigate('/')}>
            Home
        </Link>,
        <Link underline="hover" key="2" color="inherit" onClick={() => navigate('/banners')}>
            Banners
        </Link>,
        <Typography key="3" color="text.primary">
            Create Banner
        </Typography>,
    ];

    return (
        <Layout Active={'Coupons'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Create Banner</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={AddUserClasses['new-main-div']} style={{ paddingLeft: '20px' }}>
                    <div className={ViewUserClasses['main-on-ctrl-btns']}>
                        <div className={CommonClasses['control-btns']}>
                            <Button className={CommonClasses["button-for-add"]} onClick={() => navigate(-1)}><MdOutlineArrowBackIos />Back</Button>
                        </div>
                        <Stack spacing={2}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </div>
                </div>
                <div style={{ boxShadow: 'none' }} className={TutorClasses['wrapper']} data-aos="zoom-in-down">
                    <h2 style={{ paddingLeft: '7px' }} className={ProductClasses['main-header']}>Create Banner</h2>
                    <form className={CouponClasses["form"]} onSubmit={handleSubmit(onSubmit)}>

                        {/* Displaying deals */}
                        <div className={ViewUserClasses['deal-control']}>
                            <label className={ViewUserClasses['deal-label']} htmlFor="deals">Deals</label>
                            <select {...register('deal')} required>
                                {homeDeals.map((deal) => (
                                    <option key={deal.productDealId} value={deal.productDealId}>
                                        {deal.dealName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className={TutorClasses['file-upload-wrapper']}>
                            <header>
                                <h3>
                                    Upload Image <span className="important">*</span>
                                </h3>
                            </header>
                            <div className={TutorClasses["upload-image"]} required>
                                {fileuploadDesign}
                                {imageError && <p style={{ color: 'red' }}>{imageError}</p>}
                            </div>
                        </div>
                        {/* Add more input fields for other properties */}
                        <button className={CouponClasses['coup-btn']} disabled={loading}>
                            {loading ? (
                                <ReactLoading color="green" type="spokes" height={30} width={30} />
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default CreateBanner;
