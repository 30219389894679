import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import AddUserClasses from '../addUser/AddUser.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Services } from 'Services';
import { Error, Success } from 'Util/Toast'; // Assuming you have a Toast utility for notifications
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; // Assuming you are using dayjs for date handling
import EditUserClasses from './EditUser.module.css';
import { Hourglass } from 'react-loader-spinner';
import EditOrderGeneralClasses from '../../orders/editOrder/EditOrder.module.css';

const EditUserOverView = () => {
    const { userId } = useParams();
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        mobileNumber: '',
        createdAt: '', // This should be a date string (e.g., '2024-06-14')
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
        
        const fetchUserData = async () => {
            try {
                await delay(2000); // Simulate loading delay
                const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
                const response = await Services.getUserDetails(userId, token);
                if (response.Status === 1) {
                    setUserData(response.user);
                } else {
                    setError({ fetch: response.Message || 'Failed to fetch user details' });
                }
            } catch (error) {
                console.error('Error fetching user data', error);
                setError({ fetch: 'Error fetching user data' });
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        let newError = { ...error };

        // Validate email
        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                newError = { ...newError, email: 'Invalid email address' };
            } else {
                delete newError.email; // Remove error if valid
            }
        }

        // Validate phone number
        if (name === 'mobileNumber') {
            // Example: US phone number format
            const phoneRegex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
            if (!phoneRegex.test(value)) {
                newError = { ...newError, mobileNumber: 'Invalid phone number' };
            } else {
                delete newError.mobileNumber; // Remove error if valid
            }
        }

        setUserData(prevData => ({ ...prevData, [name]: value }));
        setError(newError); // Update error state
    };

    const handleDateChange = (date) => {
        setUserData(prevData => ({ ...prevData, createdAt: date.format('YYYY-MM-DD') }));
    };

    const handleSave = () => {
        // Check for any errors before proceeding
        if (Object.values(error).some(err => err)) {
            // Display error messages and prevent API call
            return;
        }

        const token = { Authorization: `Bearer ${localStorage.getItem('token')}` };
        Services.updateUser(userId, userData, token)
            .then(response => {
                if (response.Status === 1) {
                    Success('User updated successfully');
                    // Handle success, e.g., show a success message
                    console.log('User updated successfully', response);
                } else {
                    // Handle error, e.g., show an error message
                    Error(response.Message || 'Failed to update user');
                }
            })
            .catch(error => {
                console.error('Error updating user', error);
                Error('Error updating user');
            });
    };

    const handleCancel = () => {
        navigate('/users');
    };

    if (loading) {
        return (
            <div className={EditOrderGeneralClasses['spinner-container']}>
                <Hourglass
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="hourglass-loading"
                    colors={['#23232A', '#60E19B']}
                />
            </div>
        );
    }

    if (error.fetch) {
        return <div>Error: {error.fetch}</div>;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={AddUserClasses['main-of-edit-tables']}>
                <div className={EditUserClasses['main-editsave-btn']}>
                    <button className={EditUserClasses['sub-cancel-btn']} onClick={handleCancel}>
                        Cancel
                    </button>
                    <button className={EditUserClasses['sub-save-btn']} onClick={handleSave}>
                        Save Changes
                    </button>
                </div>
                <table className={AddUserClasses['user-table']}>
                    <thead>
                        <tr>
                            <th colSpan="2" className={AddUserClasses['table-header']}>About</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={AddUserClasses['table-label']}>First Name</td>
                            <td className={AddUserClasses['table-value']}>
                                <TextField
                                    required
                                    id="firstName"
                                    name="firstName"
                                    variant="outlined"
                                    className={AddUserClasses['table-textfield-all']}
                                    value={userData.firstName}
                                    onChange={handleChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={AddUserClasses['table-label']}>Last Name</td>
                            <td className={AddUserClasses['table-value']}>
                                <TextField
                                    required
                                    id="lastName"
                                    name="lastName"
                                    variant="outlined"
                                    className={AddUserClasses['table-textfield-all']}
                                    value={userData.lastName}
                                    onChange={handleChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={AddUserClasses['table-label']}>Company</td>
                            <td className={AddUserClasses['table-value']}>
                                <TextField
                                    required
                                    id="company"
                                    name="company"
                                    variant="outlined"
                                    className={AddUserClasses['table-textfield-all']}
                                    value={userData.company}
                                    onChange={handleChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={AddUserClasses['table-label']}>Email</td>
                            <td className={AddUserClasses['table-value']}>
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    variant="outlined"
                                    className={AddUserClasses['table-textfield-all']}
                                    value={userData.email}
                                    onChange={handleChange}
                                    error={Boolean(error.email)}
                                    helperText={error.email}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={AddUserClasses['table-label']}>Phone Number</td>
                            <td className={AddUserClasses['table-value']}>
                                <TextField
                                    required
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    variant="outlined"
                                    className={AddUserClasses['table-textfield-all']}
                                    value={userData.mobileNumber}
                                    onChange={handleChange}
                                    error={Boolean(error.mobileNumber)}
                                    helperText={error.mobileNumber}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={AddUserClasses['table-label']}>Date Joined</td>
                            <td className={AddUserClasses['table-value']}>
                                <DatePicker
                                    label="Date Joined"
                                    style={{ width: '400px' }}
                                    fullWidth
                                    value={userData.createdAt ? dayjs(userData.createdAt) : null}
                                    onChange={(date) => handleDateChange(date)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            margin="dense"
                                            className={AddUserClasses['table-textfield-all']}
                                        />
                                    )}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </LocalizationProvider>
    );
};

export default EditUserOverView;
